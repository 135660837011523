
import { Component, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';
import DatePicker from 'v-calendar/lib/components/date-picker.umd';
import SvgRangeCalendar from '@/assets/icons/range-calendar.svg';
import {
    formatDateInputPicker,
    getCurrentDate,
    getCurrentWeek, getCurrentYear, getFullCurrentMonth,
    getFullCurrentWeek, getFullPreviousMonth,
    getFullPreviousWeek,
    getPreviousDate,
} from '@/utils/formatDates';
import pullAll from 'lodash-es/pullAll';
import TrashBtn from "@/components/base/buttons/TrashBtn.vue";
import TimePicker from '@/components/base/pickers/TimePicker.vue';
import { eventBus } from "@/eventbus";
import SvgTrash from "@/assets/icons/trash.svg";

enum DATE_TEMPLATE {
    TODAY = 'Сегодня',
    YESTERDAY = 'Вчера',
    LAST_SEVEN_DAYS = 'Последние 7 дней',
    CURRENT_WEEK = 'За текущую неделю',
    LAST_WEEK = 'За прошлую неделю',
    CURRENT_MONTH = 'За текущий месяц',
    LAST_MONTH = 'За прошлый месяц',
    CURRENT_YEAR = 'За текущий год',
    RANDOM_PERIOD = 'Произвольный период',
}

type TDateRange = {
    start: any;
    end: any;
};

@Component({
    components: {
        SvgTrash,
        TrashBtn,
        DatePicker, SvgRangeCalendar,
        TimePicker,
    },
})
export default class DateRange extends Vue {
    @PropSync('filter', { required: true }) filterSync!: string[];
    @PropSync('period') periodTemplate!: string;
    @Prop({ type: Boolean, default: false }) readonly isDisabledSubmit!: boolean;
    @Prop({ type: Boolean, default: false }) readonly isTrashBtn!: boolean;
    @Prop({ type: Boolean, default: false }) readonly isIconActivator!: boolean;
    @Prop({ type: Boolean, default: true }) readonly isSubmitBtn!: boolean;
    @Prop({ type: Boolean, default: false }) readonly isTop!: boolean;
    @Prop({ type: Boolean, default: false }) readonly clearable!: boolean;
    @Prop({ type: String, default: 'data-range__content' }) readonly contentClass!: string;
    @Prop({ type: String, default: '' }) readonly label!: string;
    @Prop({ type: String, default: '' }) readonly labelFooterInput!: string;
    @Prop({ type: String, default: 'id' }) readonly id!: string;
    @Prop({ type: String, default: 'Сформировать' }) readonly submitBtnText!: string;
    @Prop({ type: Boolean, default: true }) readonly isUpdateAfterClear!: boolean;
    @Prop({ default: '.data-range__input-calendar-false' }) attachClass!: string | boolean;
    @Prop({ type: Boolean, default: false }) readonly isOffset!: boolean;
    @Prop({ type: Boolean, default: false }) readonly isOnlyPlaceholder!: boolean;
    @Prop({ type: Array, default: () => [] }) readonly excludingTemplate!: string[];
    @Prop({
        type: Function,
        default: () => ({ start: null, end: null }),
    }) readonly availableDates!: Function;
    @Prop({}) disabledDates!: any;

    isMenuMounted = false;
    time = {
        hour: 0,
        minutes: 0,
    };
    menu: boolean = false;
    date: null | TDateRange = null;
    focusDateForAvailableDates: null | TDateRange = this.date || {
        start: getCurrentDate('yyyy-MM-dd'),
        end: getCurrentDate('yyyy-MM-dd'),
    };
    currentDateTemplate: string = '';
    dateTemplate: string[] = [];
    formatInput = formatDateInputPicker;
    allowedDates: (date?: TDateRange | null, drag?: any) => TDateRange = () => ({ start: null, end: null });
    getAvailableDates: null | TDateRange = this.allowedDates({ start: null, end: null });
    drag: TDateRange = { start: null, end: null };

    get isMobile(): boolean {
        return this.$vuetify.breakpoint.width <= 768;
    }

    get isDisabled(): boolean {
        return this.isDisabledSubmit && this.date === null;
    }

    toggleMenu(e: boolean): void {
        this.$emit('toggle-menu', e);
    }

    @Watch('$props.availableDates', { immediate: true })
    watchApiMethod(value: any): void {
        this.allowedDates = value;
        this.updateAvailableDates();
    }

    @Watch('date')
    watchDate(): void {
        this.updateAvailableDates();
    }

    @Watch('drag')
    watchDrag(): void {
        this.updateAvailableDates();
    }

    focusInDay(e: any): void {
        this.focusDateForAvailableDates = { start: e.id, end: e.id };
    }

    // костыль, следит за изменением пропса, чтобы синхронно менялось значение с конструктора и шапки
    @Watch('filterSync')
    change(): void {
        if (this.filterSync!.length <= 0) {
            this.date = null;
            return;
        }
        if (this.filterSync!.length === 1) {
            this.date = { start: this.filterSync[0], end: this.filterSync[0] };
        } else {
            this.date = {
                start: this.filterSync[0] || getCurrentDate('yyyy-MM-dd'),
                end: this.filterSync[1] || getCurrentDate('yyyy-MM-dd'),
            };
        }
        this.getCurrentDateTemplate(this.date);
        this.periodTemplate = this.currentDateTemplate;
    }

    // TODO input нужен лишь потому что дата пикер по разному выводит формат для data
    get input(): { start: string, end: string } | string {
        if (!this.date || this.date.start === 'undefined') {
            return '';
        }
        if (typeof (this.date.start) === 'string') {
            return { start: this.date.start.substr(0, 10), end: this.date.end.substr(0, 10) };
        } else {
            return {
                start: (this.date.start as any)!.toISOString().substr(0, 10),
                end: (this.date.end as any)!.toISOString().substr(0, 10),
            };
        }
    }

    created(): void {
        eventBus.$on('check-allowed-dates', this.checkAllowedDates);
    }

    beforeDestroy(): void {
        eventBus.$off('check-allowed-dates', this.checkAllowedDates);
    }

    checkAllowedDates(dates: any): void {
        this.getAvailableDates = dates;
    }

    mounted(): void {
        if (this.filterSync[0] == undefined) {
            this.date = null;
        } else {
            this.date = {
                start: this.filterSync[0] || getCurrentDate('yyyy-MM-dd'),
                end: this.filterSync[1] || getCurrentDate('yyyy-MM-dd'),
            };
            this.getCurrentDateTemplate(this.date);
        }

        this.dateTemplate = pullAll(Object.values(DATE_TEMPLATE), this.excludingTemplate);
    }

    clearDate(): void {
        this.date = null;
        this.filterSync = [];
        this.currentDateTemplate = '';
        if (this.isUpdateAfterClear) {
            this.$emit('update', null);
        }
    }

    getCurrentDateTemplate(date: { start: string, end: string }): void {
        this.currentDateTemplate = this.switchDateTemplate([`${date.start}`, `${date.end}`]);
    }

    async submitDate(): Promise<void> {
        await this.$emit('update', this.filterSync);
        this.menu = false;
        this.toggleMenu(false);
    }

    updateAvailableDates(): void {
        this.getAvailableDates = this.date
            ? this.allowedDates(this.focusDateForAvailableDates, this.drag)
            : this.allowedDates({ start: null, end: null }, this.drag);
    }

    changeInput(): void {
        if (typeof this.input !== 'string') {
            this.filterSync = [`${this.input!.start}`, `${this.input!.end}`];
            this.getCurrentDateTemplate(this.input);
        }
        this.updateAvailableDates();
    }

    getDateFilters(condition: string[]): string[] | void {
        this.date = { start: condition[0], end: condition[1] };
        return this.filterSync = condition;
    }

    async moveCalendar(date: string | Date): Promise<void> {
        const calendar = this.$refs['calendar'];
        // @ts-ignore
        await calendar.focusDate(date);
    }

    getDateTemplate(currentDateTemplate: string): string[] | void {
        switch (currentDateTemplate) {
        case DATE_TEMPLATE.TODAY:
            return this.getDateFilters([getCurrentDate('yyyy-MM-dd'), getCurrentDate('yyyy-MM-dd')]);
        case DATE_TEMPLATE.YESTERDAY:
            return this.getDateFilters([getPreviousDate('yyyy-MM-dd'), getPreviousDate('yyyy-MM-dd')]);
        case DATE_TEMPLATE.LAST_SEVEN_DAYS:
            return this.getDateFilters(getCurrentWeek('yyyy-MM-dd'));
        case DATE_TEMPLATE.CURRENT_WEEK:
            return this.getDateFilters(getFullCurrentWeek('yyyy-MM-dd'));
        case DATE_TEMPLATE.LAST_WEEK:
            return this.getDateFilters(getFullPreviousWeek('yyyy-MM-dd'));
        case DATE_TEMPLATE.CURRENT_MONTH:
            return this.getDateFilters(getFullCurrentMonth('yyyy-MM-dd'));
        case DATE_TEMPLATE.LAST_MONTH:
            return this.getDateFilters(getFullPreviousMonth('yyyy-MM-dd', this.allowedDates()!.start));
        case DATE_TEMPLATE.CURRENT_YEAR:
            return this.getDateFilters(getCurrentYear('yyyy-MM-dd', this.allowedDates()!.start));
        }
    }

    changeDateTemplate(currentDateTemplate: string): void {
        const date = this.getDateTemplate(currentDateTemplate);
        if (date && date[0]) {
            this.moveCalendar(date[0]);
        }
    }

    switchDateTemplate(filter: any): string {
        switch (filter.join()) {
        case [getCurrentDate('yyyy-MM-dd')].join():
        case [getCurrentDate('yyyy-MM-dd'), getCurrentDate('yyyy-MM-dd')].join():
            return DATE_TEMPLATE.TODAY;
        case [getPreviousDate('yyyy-MM-dd')].join():
        case [getPreviousDate('yyyy-MM-dd'), getPreviousDate('yyyy-MM-dd')].join():
            return DATE_TEMPLATE.YESTERDAY;
        case getCurrentWeek('yyyy-MM-dd').join():
        case getCurrentWeek('yyyy-MM-dd').reverse().join():
            return DATE_TEMPLATE.LAST_SEVEN_DAYS;
        case getFullCurrentWeek('yyyy-MM-dd').join():
        case getFullCurrentWeek('yyyy-MM-dd').reverse().join():
            return DATE_TEMPLATE.CURRENT_WEEK;
        case getFullPreviousWeek('yyyy-MM-dd').join():
        case getFullPreviousWeek('yyyy-MM-dd').reverse().join():
            return DATE_TEMPLATE.LAST_WEEK;
        case getFullCurrentMonth('yyyy-MM-dd').join():
        case getFullCurrentMonth('yyyy-MM-dd').reverse().join():
            return DATE_TEMPLATE.CURRENT_MONTH;
        case getFullPreviousMonth('yyyy-MM-dd', this.allowedDates()!.start).join():
        case getFullPreviousMonth('yyyy-MM-dd', this.allowedDates()!.start).reverse().join():
            return DATE_TEMPLATE.LAST_MONTH;
        case getCurrentYear('yyyy-MM-dd', this.allowedDates()!.start).join():
        case getCurrentYear('yyyy-MM-dd', this.allowedDates()!.start).reverse().join():
            return DATE_TEMPLATE.CURRENT_YEAR;
        default:
            return DATE_TEMPLATE.RANDOM_PERIOD;
        }
    }
}
