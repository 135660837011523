
import { Component, Vue, Prop } from 'vue-property-decorator';
import { OfferSingle } from "@/services/offers/OfferSingle";
import OfferStatisticChart from "@/components/offers/offer/OfferStatisticChart.vue";
import TableWithFrontPaginationDesign from "@/components/base/table/TableWithFrontPaginationDesign.vue";
import { IItemsFromOffers } from "@/api/types/offers";
import BaseCard from "@/components/base/BaseCard.vue";

@Component({
    components: {
        BaseCard,
        TableWithFrontPaginationDesign,
        OfferStatisticChart,

    },
})
export default class OfferMetrics extends Vue {
    @Prop({ required: true }) offer!: OfferSingle;

    get stats(): {
        stats: IItemsFromOffers[],
        payouts:  IItemsFromOffers[],
        last_lead_date: string,
        last_report_date: string,
    }[] {
        return [{
            stats: this.offer.stats,
            payouts: this.offer.payouts,
            last_lead_date: this.offer.last_lead_date,
            last_report_date: this.offer.last_report_date,
        }];
    }

    headers = [
        { text: 'Статистика', value: 'stats' },
        { text: 'Выплата', value: 'payouts' },
        { text: 'Последний принятый лид', sortable: false, value: 'last_lead_date' },
        { text: 'Загрузка отчета', sortable: false, value: 'last_report_date' },
    ];
    timeData = [
        {
            slot: 'item.last_lead_date',
            key: 'last_lead_date',
            isLine: true,
        },
        {
            slot: 'item.last_report_date',
            key: 'last_report_date',
            isLine: true,
        },
    ];
    uniqueDate = [
        {
            slot: 'item.stats',
            template: ({ stats }): string => this.mapArrayFields(stats),
        },
        {
            slot: 'item.payouts',
            template: ({ payouts }): string => this.mapArrayFields(payouts),
        },
    ];

    mapArrayFields(items: IItemsFromOffers[]): string {
        return items?.map((i) => i.name).join(', ');
    }

    get isOfferEmpty(): boolean {
        return Object.keys(this.offer)?.length <= 0;
    }
}
