
import { Vue, Component, Prop, Ref } from "vue-property-decorator";
import BaseCard from "@/components/base/BaseCard.vue";
import { OfferSingle } from "@/services/offers/OfferSingle";
import { IEditableCaps, IEditableOfferFields } from "@/api/types/offers";
import BaseAutocomplete from "@/components/base/design/BaseAutocomplete.vue";
import { getEmployeesOffersV2 } from "@/api/offers";
import { eventBus } from "@/eventbus";
import BaseSelect from "@/components/base/design/BaseSelect.vue";
import EditModeTextField from "@/components/base/form/EditModeTextField.vue";
import MaskedTextField from "@/components/base/form/MaskedTextField.vue";

@Component({
    methods: { getEmployeesOffersV2 },
    components: { MaskedTextField, EditModeTextField, BaseSelect, BaseAutocomplete, BaseCard },
})

export default class OfferLimitsTab extends Vue {
    @Prop({ required: true }) offer!: OfferSingle;
    @Ref() card!: BaseCard;

    editMode = false;
    caps: IEditableCaps = {
        enabled: false,
        daily_conversion: 0,
        daily_payout:  0,
        daily_revenue:  0,
        monthly_conversion: 0,
        monthly_payout:  0,
        monthly_revenue:  0,
    };
    limits = [
        {
            id: 'conversion',
            name: 'Лимиты по количеству конверсий:',
            items: [
                { id: 'daily_conversion', name: 'В день' },
                { id: 'monthly_conversion', name: 'В месяц' },
            ],
        },
        {
            id: 'payout',
            name: 'Лимиты по выплате:',
            items: [
                { id: 'daily_payout', name: 'В день' },
                { id: 'monthly_payout', name: 'В месяц' },
            ],
        },
        {
            id: 'revenue',
            name: 'Лимиты по доходу:',
            items: [
                { id: 'daily_revenue', name: 'В день' },
                { id: 'monthly_revenue', name: 'В месяц' },
            ],
        },
    ];

    get offerCurrency(): string {
        const firstGoalRevenue = this.offer.goals[0]?.revenue;
        if (!firstGoalRevenue) return  '';
        return firstGoalRevenue.type === 'fixed' ? firstGoalRevenue.currency : '%';
    }

    mounted(): void {
        this.setDefaults();
        this.caps!.enabled = !!this.offer.caps?.enabled;
    }

    setDefaults(): void {
        this.caps!.daily_conversion = this.offer.caps?.daily?.conversion || 0;
        this.caps!.daily_revenue = this.offer.caps?.daily?.revenue || 0;
        this.caps!.daily_payout = this.offer.caps?.daily?.payout || 0;
        this.caps!.monthly_conversion = this.offer.caps?.monthly?.conversion || 0;
        this.caps!.monthly_revenue = this.offer.caps?.monthly?.revenue || 0;
        this.caps!.monthly_payout = this.offer.caps?.monthly?.payout || 0;
    }

    async updateAllOfferData(): Promise<void> {
        const copyData = { ...this.caps };
        const isOfferDataSaved = await this.offer.editOffer({ caps : copyData } as IEditableOfferFields);
        if (isOfferDataSaved) {
            eventBus.$emit("update-offer");
        }
    }

    editInfo(edit: boolean): void {
        if (edit) {
            this.setDefaults();
        } else {
            this.updateAllOfferData();
        }
        this.editMode = edit;
    }

    cancelEdit(): void {
        this.setDefaults();
        this.editMode = false;
    }

    toggleEnabled(): void {
        if (!this.caps.enabled) {
            this.editMode = false;
            this.card.cancel();
        }
        this.editInfo(false);
    }
}

