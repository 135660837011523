
import { Component, Vue } from 'vue-property-decorator';
import SvgApplication from '@/assets/icons/nav-bar/application.svg';
import { showServerError } from '@/utils';
import { IPersonalRates, IRatesHistory } from '@/api/types/offers';
import { getEmployeesPersonalRate, postEmployeesPersonalRates } from '@/api/offers';
import PersonalRateInfo, { IPersonalRateInfo } from '@/components/offers/personal-rates/PersonalRateInfo.vue';
import { Getter, Mutation } from 'vuex-class';
import PersonalRateHeader from "@/components/offers/personal-rates/PersonalRateHeader.vue";
import FormDeletePersonalRate from "@/components/offers/personal-rates/FormDeletePersonalRate.vue";
import { translatePersonalRateStatus } from "@/utils/translate";
import PageLayout from "@/components/layout/PageLayout.vue";
import Status from '@/components/base/Status.vue';
import TrashBtn from "@/components/base/buttons/TrashBtn.vue";
import orderBy from "lodash-es/orderBy";
import { getCurrentDate } from "@/utils/formatDates";
import { NotificationMessage } from "@/utils/notification";
import { parseISO } from "date-fns";
import PersonalRateHistory from "@/components/offers/personal-rates/PersonalRateHistory.vue";
import SvgCopy from "@/assets/icons/copy.svg";
import SvgPlayBack from "@/assets/icons/play-black.svg";

@Component({
    beforeRouteEnter(to: any, from: any, next: (arg0?: (vm: any) => void) => void) {
        next(vm => {
            if (from.fullPath !== '/') {
                vm.beforeListRoute = from.fullPath;
            }
        });
    },
    components: {
        SvgCopy,
        PersonalRateHistory,
        TrashBtn,
        PageLayout,
        PersonalRateHeader,
        SvgApplication,
        PersonalRateInfo,
        FormDeletePersonalRate,
        Status,
        SvgPlayBack,
    },
})
export default class PersonalRate extends Vue {
    @Mutation('TOGGLE_EDITING_MODE') toggleEditingMode;
    @Getter('GET_EDITING_MODE') editingMode;
    beforeListRoute = '/offers/personal-rates';

    isDeletePersonalRate = false;
    personalRate = {} as IPersonalRates;
    personalRateId: string = '';
    translatePersonalRateStatus = translatePersonalRateStatus;
    settings = { title: 'История изменений', loading: true };

    get title(): string {
        return `Индивидуальная цена ${this.personalRateId}`;
    }

    get ratesHistory(): IRatesHistory[] {
        return orderBy(this.personalRate.rates_history, ['date'], ['desc']);
    }

    get isPersonalRateEmpty(): boolean {
        return Object.keys(this.personalRate).length > 0;
    }

    editPersonalRate(item: IPersonalRateInfo): void {
        this.personalRate.personal_rates[item.key].amount = item.value;
    }

    updateTime(value: string): void {
        this.personalRate.personal_rates.start_date = value;
    }

    deletePersonalRate(): void {
        this.showNotification(`Цена ${this.personalRate.personal_rates.id} удалена`, 'info');
        this.isDeletePersonalRate = false;
        this.$router.push({ name: 'personal-rates' });
    }

    showNotification(text: string, type: string): void {
        new NotificationMessage(text, type);
    }

    async activatePersonalRate(): Promise<void> {
        try {
            await postEmployeesPersonalRates({
                pairs: [{
                    offer_id: this.personalRate.offer.legacy_id,
                    goal_id: this.personalRate.goal.legacy_id,
                    affiliate_id: this.personalRate.affiliate.legacy_id,
                }],
            });
            this.showNotification(`Цена ${this.personalRate.personal_rates.id} будет активирована в ближайшее время`, 'success');
            await this.getPersonalRate();
        } catch (err) {
            showServerError(err, 'Индивидуальная цена не активирована');
        }
    }

    async updatePersonalRate(): Promise<void> {
        if (this.editingMode) {
            try {
                const rate = {
                    revenue: this.personalRate.personal_rates.revenue.amount,
                    payout: this.personalRate.personal_rates.payout.amount,
                    start_date: this.personalRate.personal_rates.start_date,
                    offer_id: this.personalRate.offer.legacy_id,
                    goal_id: this.personalRate.goal.legacy_id,
                    affiliate_id: this.personalRate.affiliate.legacy_id,
                };

                if (parseISO(rate.start_date!) <= new Date()) {
                    delete rate.start_date;
                }

                await postEmployeesPersonalRates({ pairs: [rate] });
                this.showNotification(`Цена ${this.personalRate.personal_rates.id} отредактирована`, 'success');

                await this.getPersonalRate();
            } catch (err) {
                showServerError(err, 'Ошибка сохранения');
            }
        } else {
            this.personalRate.personal_rates.start_date = getCurrentDate('yyyy-MM-dd HH:mm');
        }
        this.toggleEditingMode(!this.editingMode);
    }

    async getPersonalRate(): Promise<void> {
        this.settings.loading = true;
        try {
            this.personalRate = await getEmployeesPersonalRate(this.personalRateId);
            this.personalRate.rates_history = this.personalRate.rates_history.reverse();
        } catch (err) {
            showServerError(err, 'Индивидуальная цена не найдена');
        } finally {
            this.settings.loading = false;
        }
    }

    backToList(): void {
        this.$router.push({ path: this.beforeListRoute });
    }

    created(): void {
        this.toggleEditingMode(false);
        this.personalRateId = this.$route.params.id;
        this.getPersonalRate();
        document.title = `Цена ${this.personalRateId}`;
    }

    beforeDestroy(): void {
        this.toggleEditingMode(true);
    }
}
