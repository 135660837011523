
import { Component, Prop, PropSync, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { Getter, Mutation } from 'vuex-class';
import FiltersDesign from '@/components/base/filters/FiltersDesign.vue';
import ConstructorWrap from '@/components/statistics/ConstructorWrap.vue';
import ColumnsCheckboxes from '@/components/statistics/ColumnsCheckboxes.vue';
import StatisticReportMixin from '@/mixins/statisticReport';
import { getOneEmployeesOffer } from '@/api/offers';
import { showServerError } from '@/utils';
import SelectDesign from '@/components/base/form/SelectDesign.vue';
import { IFilter } from '@/types';
import MultiselectDesign from '@/components/base/form/MultiselectDesign.vue';
import ColumnsSubs from '@/mixins/columnsSubs';
import { conversionFilterCols } from "@/services/TablePage/conversionReport";

export const baseColumns = [
    'adv_sub',
    'affiliate_id',
    'affiliate_invoice_id',
    'affiliate_name',
    'click_id',
    'conversion_id',
    'conversion_date',
    'currency',
    'goal_id',
    'goal_name',
    'offer_id',
    'offer_name',
    'payout',
    'revenue',
    'status',
];

@Component({
    components: {
        ColumnsCheckboxes,
        ConstructorWrap,
        FiltersDesign,
        MultiselectDesign,
        SelectDesign,
    },
})
export default class ConstructorConversion extends mixins(StatisticReportMixin, ColumnsSubs) {
    @Prop({ type: String, required: true }) readonly attachClass!: string;
    @Prop({ type: Object, required: true }) readonly getEmptyConditions!: {};
    @PropSync('filters', { required: true }) filtersSync!: any;
    @Getter('GET_FILTERS_PARAMS_OBJECT') getFiltersParamsObject;
    @Mutation('SET_FILTERS_PARAMS_OBJECT') setFiltersParamsObject;

    tabItems = ['Колонки', 'Фильтры'];
    tab: number | number[] = 1;
    selected: string[] = baseColumns;
    isShowConstructor = false;
    filterCols = conversionFilterCols;

    showCheckbox(value: number): boolean {
        if (Array.isArray(this.tab)) {
            return this.tab.includes(value);
        } else {
            return this.tab === value;
        }
    }

    clearAll(): void {
        this.selected = baseColumns;
        this.clearSubs();
        this.$emit('clear-all');
        this.setDefaultFiltersParamObject({ status: ['approved'] });
    }

    created(): void {
        if (this.$route.query.hasOwnProperty('columns')) {
            this.checkQuery();
            this.selected = Array.isArray(this.$route.query.columns) ? [...this.$route.query.columns] as string[] : [this.$route.query.columns] as string[];
        }
    }

    get currentGoal(): IFilter | undefined {
        return this.filtersSync.find((filter) => filter.id === 'goal_id');
    }

    get currentAdvSub(): IFilter | undefined {
        return this.filtersSync.find((filter) => filter.id === 'adv_sub');
    }

    get currentOffer(): [] | [{ id: number, legacy_id: number, name: string }] {
        const offerFilter = this.filtersSync.find((filter) => filter.id === 'offer_id');
        return offerFilter ? offerFilter.select : [];
    }

    checkQuery(): void {
        const allQuery = this.filterCols.flatMap(f => f.items.map(i => i.value));
        const currQuery = [...this.$route.query.columns!] as string[];
        const brokenQuery = currQuery.filter(q => !allQuery.includes(q));
        const correctQuery = currQuery.filter(q => !brokenQuery.includes((q)));
        this.updateQueryString({ columns: [...correctQuery] });
        this.$emit('update-columns');
    }

    updateGoal(filter: IFilter): void {
        if (!filter.select.length) {
            this.currentGoal!.select = [];
        }
    }

    @Watch('currentOffer')
    getGoalItems(): void {
        this.currentGoal!.items = [];
        this.currentGoal!.tooltip = 'Сначала отфильтруйте отчёт по офферу';
        this.currentGoal!.disabled = this.currentOffer.length !== 1;
        this.currentAdvSub!.disabled = this.currentOffer.length <= 0;
        if ((this.currentOffer.length > 0 && this.getFiltersParamsObject.offer_id && this.getFiltersParamsObject.offer_id[0] !== this.currentOffer[0]!.id!) || this.currentOffer.length > 1) {
            this.currentGoal!.select = [];
        }
        if (this.currentOffer.length <= 0) {
            this.currentAdvSub!.select = '';
        }
        if (this.currentGoal && this.currentOffer.length > 1) {
            this.currentGoal!.tooltip = 'Фильтр по цели возможен только если отчёт отфильтрован по одному офферу';
        }
        if (this.currentGoal && this.currentOffer.length === 1) {
            this.getGoals(this.currentOffer[0].legacy_id);
        }
    }

    async getGoals(item: number): Promise<void> {
        try {
            const { goals } = await getOneEmployeesOffer(item, { by_legacy_id: 1 });
            this.currentGoal!.items = goals;

            // прокидываем значение из урла, после получения items
            if (this.$route.query.hasOwnProperty('goal_id') && this.currentGoal!.select.length <= 0) {
                const goalIds = this.$route.query.goal_id;
                if (!goalIds || goalIds === 'NaN') return;
                this.currentGoal!.select = Array.isArray(goalIds) ? goalIds.map(g => +g!) : [Number(goalIds)];
            }
        } catch (e) {
            showServerError(e, 'Список целей оффера не загружен');
        }
    }

    getConversions(offset?: number): void {
        this.isShowConstructor = false;
        this.$emit('submit', offset);
        this.updateUrl();
        this.$emit('update-columns');
    }

    @Watch('$route.query')
    updateUrl(): void {
        this.updateQueryString({ columns: [...this.selected] });
    }
}
