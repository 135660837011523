
import { Vue, Component, Prop } from "vue-property-decorator";
import AppTableDesign from "@/components/base/table/AppTableDesign.vue";
import { Offer } from "@/services/offers/Offer";
import { translateFinancialStatus, translateStatisticsStatus } from "@/utils/translate";
import { IInvoice } from "@/api/types/revenue";
import { deleteInvoice } from "@/api/revenue";
import { NotificationMessage } from "@/utils/notification";
import { showServerError } from "@/utils";
import FormModal from "@/components/base/FormModal.vue";
import { IStatus } from "@/types";
import { getFormatDate } from '@/utils/formatDates';

@Component({
    components: { FormModal, AppTableDesign },
})

export default class InvoicesTable extends Vue {
    @Prop({ required: true }) items!: Offer[];
    @Prop({ default: 100 }) limit!: number;
    @Prop({ default: 0 }) total!: number;
    @Prop({ default: true }) loading!: boolean;

    headers = [
        { text: 'ID', sortable: false, value: 'id', align: 'center' },
        { text: 'Период', sortable: false, value: 'period' },
        { text: 'Рекламодатель', sortable: false, value: 'advertiser.name' },
        { text: 'Аккаунт-менеджер', sortable: false, value: 'advertiser.account_manager.full_name' },
        { text: 'Статус статистики', sortable: false, align: 'center', value: 'statistics_status' },
        { text: 'Финансовый статус', sortable: false, align: 'center', value: 'financial_status' },
        { text: 'Сумма услуг', sortable: false, value: 'total_by_invoice', align: 'right' },
        { text: 'Сумма взаимозачёта', sortable: false, value: 'netting_total_paid_sum', align: 'right' },
        { text: 'Сумма заказов покупателей', sortable: false, value: 'orders_total', align: 'right' },
        { text: 'Акция', sortable: false, value: 'other_services', align: 'left' },
        { text: 'Действия', sortable: false, align: 'center', value: 'actions' },
    ];

    timeData = [
        {
            slot: 'item.period',
            key: 'period',
            dateFormat: 'LLLL yyyy',
            isWithTime: false,
        },
    ];

    uniqueData = [
        {
            slot: 'item.other_services',
            template: (items: any): string => items.other_services ? items.other_services.join(', ') : '',
        },
        {
            slot: 'item.advertiser.account_manager.full_name',
            template: (item: any): string => `${item.advertiser.account_manager.first_name || ''} ${item.advertiser.account_manager.last_name || ''}`,
        },
    ];

    routes = [
        {
            routeParam: 'id',
            slot: 'item.advertiser.name',
            routerName: 'advertiser',
            text: 'name',
            sup: 'advertiser',
        },
        {
            routeParam: 'id',
            slot: 'item.id',
            routerName: 'invoice',
            text: 'id',
        },
    ];

    // для отображения статусов в таблице
    statuses = [
        {
            slot: 'item.financial_status',
            key: 'financial_status',
            translate: this.translateStatus,
        },
        {
            slot: 'item.statistics_status',
            key: 'statistics_status',
            translate: translateStatisticsStatus,
        },
        {
            slot: 'item.account_status',
            key: 'account_status',
        },
    ];

    numberFormatting = [
        {
            slot: 'item.total_by_invoice',
            key: 'total_by_invoice',
        },
        {
            slot: 'item.netting_total_paid_sum',
            key: 'netting_total_paid_sum',
        },
        {
            slot: 'item.orders_total',
            key: 'orders_total',
        },
    ];

    deletingInvoice: { id: number } = {
        id: 0,
    };
    actions = [
        {
            title: 'Удалить',
            color: '#1B1B1D',
            icon: 'SvgTrashWhite',
            isTooltip: true,
            callback: ({ id }: {id: number}): void => {
                this.deletingInvoice.id = id;
            },
        },
    ];

    get getDeleteItemText(): { text: string, index: number } {
        const item = this.items.find((item) => item.id === this.deletingInvoice.id);
        const accountName = item?.advertiser?.name;
        const formatPeriod = item?.period !== undefined ? getFormatDate(item?.period!, 'LLLL yyyy') : '';
        return {
            text: `Удалить счёт ${accountName} за ${formatPeriod}?`,
            index: 0,
        };
    }

    translateStatus(status: string, invoice?: IInvoice): IStatus {
        if (invoice && invoice!.is_draft) return { text: 'Черновик', color: '' };
        return translateFinancialStatus(status);
    }

    async deleteOneInvoice(): Promise<void> {
        try {
            const index = this.items.findIndex((item) => item.id === this.deletingInvoice.id);
            await deleteInvoice(this.deletingInvoice.id);
            this.items.splice(index, 1);
            new NotificationMessage(`Счёт ${this.deletingInvoice.id} удалён`, 'info' );
            this.deletingInvoice.id = 0;
            this.$emit('update-table');
        } catch (err) {
            showServerError(err, 'Счет не может быть удален');
        }
    }

    submitData(offset: number, limit: number): void {
        this.$emit('update-table', offset, limit);
    }
}

