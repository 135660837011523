import { IColumnFilter, IFilter, TableHeader } from "@/types";
import { getPayoutMasterAccounts } from "@/api/payout";
import { getAffiliateSearch, getActiveUserSearch } from "@/api/user";
import { getEmployeesBufferAccounts, getEmployeesOffersV2 } from "@/api/offers";
import {
    translateGoalStatus,
    translateOffersStatus,
    translatePartnerStatus,
    translatePersonalRateStatus,
} from "@/utils/translate";
import { CreatedColumns } from "@/services/columns/columns";
import { CreatedFilters } from "@/services/filters/filters";
import { IBufferAccount } from "@/api/types/offers";
import { showServerError } from "@/utils";
import store from "@/store";
import { filterClass } from '@/services/filters/filterClass';
import router from "@/router";
import { getAdvertisersSearch } from "@/api/revenue";

const getTemplate = (item: any): string => {
    return item.name || item.code || item.master.id;
};

export class PersonalRateFilters extends CreatedFilters {
    constructor() {
        super();

        (async () => {
            await Promise.all([
                this.setSlaves('affiliate_id'),
            ]);
        })();
    }

    async getBufferAccountsItems(): Promise<void> {
        try {
            const filterBuffer = this.filters.find(i => i.id === 'buffer_account_id');
            if (filterBuffer?.items?.length! > 0) return;
            filterBuffer!.items = await getEmployeesBufferAccounts();
        } catch (err) {
            showServerError(err, 'Буферные аккаунты не загружены');
        }
    }

    getFilters(): IFilter[] {
        const offer = store.getters['offersModule/GET_CURRENT_OFFER'];
        const affiliate = store.getters['GET_CURRENT_AFFILIATE'];
        const isMaster = store.state.isMasterAffiliate;
        const isOfferRoute = this.checkRoute('offer');
        const isAffiliateRoute = this.checkRoute('affiliate');

        return [
            new filterClass.Multiselect({
                id: 'status',
                label: 'Статус цены',
                type: 'string',
                select: ['active'],
                defaultValue: ['active'],
                items: [{ id: 'active' }, { id: 'pending' }, { id: 'deleted' }, { id: 'paused' }],
                template: ({ id }) => this.translateStatus(id, translatePersonalRateStatus),
                itemText: ({ id }) => this.translateStatus(id, translatePersonalRateStatus),
                itemValue: 'id',
                noDataText: 'Нет статусов',
            }),
            new filterClass.Combobox({
                id: 'buffer_account_id',
                template: (item: IBufferAccount): string | number => item.id,
                label: 'Буфер',
                itemValue: 'id',
                itemText: 'id',
                select: [],
                items: [],
                type: 'number',
                multiPaste: true,
                noDataText: 'Нет данных',
                isHidden: isAffiliateRoute && !isMaster,
            }),
            new filterClass.Autocomplete({
                id: 'master_account_id',
                apiMethod: getPayoutMasterAccounts,
                key: 'search',
                queryValue: (item: number): number => item,
                label: 'Мастер-аккаунт',
                itemValue: 'id',
                itemText: 'text',
                select: isAffiliateRoute && isMaster ? [affiliate] : [],
                defaultValue: isAffiliateRoute && isMaster ? [affiliate] : [],
                items: [],
                template: getTemplate,
                noDataText: 'Нет мастер-аккаунтов',
                multiple: true,
                multiPaste: true,
                isHidden: isAffiliateRoute,
            }),
            new filterClass.Autocomplete({
                id: 'affiliate_id',
                apiMethod: isAffiliateRoute && isMaster ? undefined : getAffiliateSearch,
                label: 'Партнер',
                itemValue: 'id',
                itemText: 'name',
                template: (i) => `${i.id} ${i.first_name} ${i.last_name} ${i.email}`,
                select: isAffiliateRoute && !isMaster ? [affiliate] : [],
                defaultValue: isAffiliateRoute && !isMaster ? [affiliate] : [],
                items: [],
                multiple: true,
                multiPaste: true,
                alsoSearchBy: 'email',
                isHidden: isAffiliateRoute && !isMaster,
            }),
            new filterClass.Multiselect({
                id: 'affiliate_status',
                label: 'Статус партнёра',
                type: 'string',
                select: isAffiliateRoute && !isMaster ? [] : ['active'],
                defaultValue: isAffiliateRoute && !isMaster ? [] : ['active'],
                items: [
                    { id: 'active' },
                    { id: 'pending' },
                    { id: 'blocked' },
                    { id: 'rejected' },
                    { id: 'deleted' },
                ],
                template: ({ id }) => this.translateStatus(id, translatePartnerStatus),
                itemText: ({ id }) => this.translateStatus(id, translatePartnerStatus),
                itemValue: 'id',
                noDataText: 'Нет статусов',
                isHidden: isAffiliateRoute && !isMaster,
            }),
            new filterClass.Autocomplete({
                id: 'affiliate_manager_id',
                apiMethod: getActiveUserSearch,
                label: 'Менеджер партнера',
                itemValue: 'id',
                itemText: 'name',
                select: [],
                items: [],
                multiple: true,
                isHidden: isAffiliateRoute,
            }),
            new filterClass.Autocomplete({
                id: 'advertiser_id',
                apiMethod: getAdvertisersSearch,
                label: 'Рекламодатель',
                itemValue: 'id',
                itemText: 'name',
                select: [],
                items: [],
                multiple: true,
                isHidden: isOfferRoute,
            }),
            new filterClass.Autocomplete({
                id: 'offer_legacy_id',
                apiMethod: getEmployeesOffersV2,
                key: 'search',
                label: 'Оффер',
                itemValue: 'legacy_id',
                itemText: 'name',
                select: isOfferRoute ? [offer] : [],
                defaultValue: isOfferRoute ? [offer] : [],
                items: [],
                multiple: true,
                supValue: 'legacy_id',
                multiPaste: true,
                isHidden: isOfferRoute,
            }),
            new filterClass.Multiselect({
                id: 'offer_status',
                label: 'Статус оффера',
                type: 'string',
                select: !isOfferRoute ? ['active'] : [],
                defaultValue: !isOfferRoute ? ['active'] : [],
                items: [
                    { id: 'active' },
                    { id: 'paused' },
                    { id: 'deleted' },
                    { id: 'pending' },
                    { id: 'expired' },
                ],
                template: ({ id }) => (this.translateStatus(id, translateOffersStatus)),
                itemText: ({ id }) => (this.translateStatus(id, translateOffersStatus)),
                itemValue: 'id',
                noDataText: 'Нет статусов',
                isHidden: isOfferRoute,
            }),
            new filterClass.Select({
                id: 'goal_status',
                label: 'Статус цели',
                type: 'string',
                select: 'active',
                defaultValue: 'active',
                items: ['active', 'deleted'],
                translate: (i) => (translateGoalStatus(i).text),
                itemText: (i) => (translateGoalStatus(i).text),
                noDataText: 'Нет статусов',
            }),
            new filterClass.Autocomplete({
                id: 'advertiser_manager_id',
                apiMethod: getActiveUserSearch,
                label: 'Менеджер рекламодателя',
                itemValue: 'id',
                itemText: 'name',
                select: [],
                items: [],
                multiple: true,
                isHidden: isOfferRoute,
            }),
            new filterClass.Checkbox({
                label: 'Только неподтверждённые',
                id: 'is_approved',
                select: [],
                itemText: 'false',
                type: 'string',
            }),
            new filterClass.Checkbox({
                label: 'Только некорректные',
                id: 'is_correct',
                select: [],
                itemText: 'false',
                type: 'string',
            }),
            new filterClass.Checkbox({
                label: 'Только запланированные',
                id: 'is_planned',
                select: [],
                itemText: 'true',
                type: 'string',
            }),
        ];
    }
}

export class PersonalRateColumns extends CreatedColumns {
    constructor() {
        super();
    }

    get getColumnsForAffiliateDetailPage(): IColumnFilter[] {
        if (store.state.isMasterAffiliate) return this.columns;
        return this.columns.filter(group => group.id !== 'affiliate');
    }

    get isDetailOffer(): boolean {
        return router.currentRoute.name === 'offer';
    }

    getColumns(): IColumnFilter[] {
        return [
            {
                id: 'affiliate',
                value: false,
                name: 'Партнёр',
                items: [
                    { title: 'ID партнёра', value: 'affiliate.legacy_id' },
                    { title: 'Имя', value: 'affiliate.name' },
                    { title: 'Статус', value: 'affiliate.status' },
                    { title: 'ID буфера', value: 'affiliate.buffer_account_id' },
                    { title: 'Имя буфера', value: 'affiliate.buffer_account_name' },
                    { title: 'Менеджер', value: 'affiliate.manager.name' },
                ],
            },
            {
                id: 'rate',
                value: false,
                name: 'Обычная цена',
                items: [
                    { title: 'Доход', value: 'goal.revenue' },
                    { title: 'Выплата', value: 'goal.payout' },
                    { title: 'Прибыль', value: 'goal.margin.amount' },
                    { title: 'Прибыль(%)', value: 'goal.margin.percent' },
                ],
            },
            {
                id: 'offer',
                value: false,
                name: 'Оффер',
                items: this.isDetailOffer
                    ? [
                        { title: 'ID цели', value: 'goal.legacy_id' },
                        { title: 'Цель', value: 'goal.name' },
                        { title: 'Статус цели', value: 'goal.active' },
                    ] : [
                        { title: 'ID', value: 'offer.legacy_id' },
                        { title: 'Оффер', value: 'offer.name' },
                        { title: 'Статус оффера', value: 'offer.status' },
                        { title: 'ID цели', value: 'goal.legacy_id' },
                        { title: 'Цель', value: 'goal.name' },
                        { title: 'Статус цели', value: 'goal.active' },
                        { title: 'Менеджер', value: 'offer.advertiser.manager.name' },
                    ],
            },
            {
                id: 'personal-rate',
                value: false,
                name: 'Индивидуальная цена',
                items: [
                    { title: 'ID', value: 'personal_rates.id' },
                    { title: 'Доход', value: 'personal_rates.revenue' },
                    { title: 'Выплата', value: 'personal_rates.payout' },
                    { title: 'Прибыль', value: 'personal_rates.margin.amount' },
                    { title: 'Прибыль(%)', value: 'personal_rates.margin.percent' },
                    { title: 'Статус цены', value: 'status' },
                    { title: 'Время запуска', value: 'personal_rates.action_date' },
                ],
            },
        ];
    }
}

export const personalRatesTableHeaders: TableHeader[] = [
    { text: 'ID цены', sortable: false, value: 'personal_rates.id', align: 'center', customSort: 'id' },
    {
        section: 'Партнёр',
        text: 'ID',
        sortable: false,
        value: 'affiliate.legacy_id',
        align: 'center',
        customSort: 'affiliate_id',
    },
    { section: 'Партнёр', text: 'Имя', sortable: false, value: 'affiliate.name' },
    { section: 'Партнёр', text: 'Статус', sortable: false, value: 'affiliate.status' },
    { section: 'Партнёр', text: 'ID буфера', sortable: false, value: 'affiliate.buffer_account_id' },
    { section: 'Партнёр', text: 'Имя буфера', sortable: false, value: 'affiliate.buffer_account_name' },
    { section: 'Партнёр', text: 'Менеджер', sortable: false, value: 'affiliate.manager.name' },
    { section: 'Оффер', text: 'ID', sortable: false, value: 'offer.legacy_id', align: 'center', customSort: 'offer_id' },
    { section: 'Оффер', text: 'Оффер', sortable: false, value: 'offer.name', width: '150px', customSort: 'offer_name' },
    { section: 'Оффер', text: 'Статус оффера', sortable: false, value: 'offer.status', align: 'left', width: '105px' },
    {
        section: 'Оффер',
        text: 'ID цели',
        sortable: false,
        value: 'goal.legacy_id',
        align: 'center',
        customSort: 'goal_id',
    },
    { section: 'Оффер', text: 'Цель', sortable: false, value: 'goal.name', width: '180px', customSort: 'goal_name' },
    { section: 'Оффер', text: 'Статус цели', sortable: false, value: 'goal.active' },
    {
        section: 'Оффер',
        text: 'Менеджер',
        sortable: false,
        value: 'offer.advertiser.manager.name',
        customSort: 'advertiser_manager_name',
    },
    {
        section: 'Обычная цена',
        text: 'Доход',
        sortable: false,
        value: 'goal.revenue',
        align: 'right',
        customSort: 'goal_revenue',
    },
    {
        section: 'Обычная цена',
        text: 'Выплата',
        sortable: false,
        value: 'goal.payout',
        align: 'right',
        customSort: 'goal_payout',
    },
    { section: 'Обычная цена', text: 'Прибыль', sortable: false, value: 'goal.margin.amount', align: 'right' },
    { section: 'Обычная цена', text: '%', sortable: false, value: 'goal.margin.percent', align: 'right', width: '55px' },
    {
        section: 'Индивидуальная цена',
        text: 'Дата начала',
        sortable: false,
        value: 'personal_rates.start_date',
        isShowOnlyOnEdit: true,
        align: 'left',
        width: '123px',
    },
    {
        section: 'Индивидуальная цена',
        text: 'Доход',
        sortable: false,
        value: 'personal_rates.revenue',
        align: 'right',
        customSort: 'personal_revenue',
    },
    {
        section: 'Индивидуальная цена',
        text: 'Выплата',
        sortable: false,
        value: 'personal_rates.payout',
        align: 'right',
        customSort: 'personal_payout',
    },
    {
        section: 'Индивидуальная цена',
        text: '%',
        sortable: false,
        value: 'personal_rate_payout_percent',
        align: 'right',
        fixed: true,
        width: '55px',
        class: 'payout-percent-col',
    },
    {
        section: 'Индивидуальная цена',
        text: 'Прибыль',
        sortable: false,
        value: 'personal_rates.margin.amount',
        align: 'right',
    },
    {
        section: 'Индивидуальная цена',
        text: '%',
        sortable: false,
        value: 'personal_rates.margin.percent',
        align: 'right',
        width: '55px',
    },
    { section: 'Индивидуальная цена', text: 'Статус', sortable: false, value: 'status', align: 'left' },
    {
        section: 'Индивидуальная цена',
        fixed: true,
        text: 'Запуск',
        sortable: false,
        value: 'personal_rates.action_date',
        align: 'center',
    },
    { text: 'Действия', sortable: false, align: 'center', value: 'actions', width: '50px', fixed: true },
];

export const personalRatesTableStatuses = [
    {
        slot: 'item.offer.status',
        sup: 'offer',
        key: 'status',
        translate: translateOffersStatus,
    },
    {
        slot: 'item.affiliate.status',
        sup: 'affiliate',
        key: 'status',
        translate: translatePartnerStatus,
    },
    {
        slot: 'item.status',
        key: 'status',
        translate: translatePersonalRateStatus,
    },
    {
        slot: 'item.goal.active',
        sup: 'goal',
        key: 'active',
        translate: translateGoalStatus,
    },
];
export const personalRatesTableRoutes = [
    {
        routeParam: 'id',
        slot: 'item.affiliate.legacy_id',
        routerName: 'affiliate',
        text: 'legacy_id',
        getParams(item: any): { id: string } {
            return {
                id: item.affiliate.legacy_id!,
            };
        },
        template: (item: any): string => item.affiliate.legacy_id,
    },
    {
        template: (item: any): string => item.affiliate.name,
        getParams(item: any): { id: string } {
            return {
                id: item.affiliate.legacy_id!,
            };
        },
        routeParam: 'id',
        routerName: 'affiliate',
        slot: 'item.affiliate.name',
        text: 'affiliate.name',
    },
    {
        routeParam: 'id',
        slot: 'item.affiliate.buffer_account_id',
        routerName: 'affiliate',
        text: 'buffer_account_id',
        getParams(item: any): { id: string } {
            return {
                id: item.affiliate.legacy_id!,
            };
        },
        template: (item: any): string => item.affiliate.buffer_account_id,
    },
    {
        template: (item: any): string => item.affiliate.buffer_account_name,
        getParams(item: any): { id: string } {
            return {
                id: item.affiliate.buffer_account_name!,
            };
        },
        routeParam: 'id',
        routerName: 'affiliate',
        slot: 'item.affiliate.buffer_account_name',
        text: 'buffer_account_name',
    },
    {
        routerName: 'offer',
        slot: 'item.offer.name',
        text: 'offer.name',
        routeParam: 'id',
        template: (item: any): string => item.offer.name,
        getParams(item: any): { id: string } {
            return {
                id: item.offer.legacy_id,
            };
        },
    },
    {
        routerName: 'offer',
        slot: 'item.offer.legacy_id',
        text: 'offer.legacy_id',
        routeParam: 'id',
        template: (item: any): string => item.offer.legacy_id,
        getParams(item: any): { id: string } {
            return {
                id: item.offer.legacy_id,
            };
        },
    },
    {
        routerName: 'offer-goal',
        slot: 'item.goal.legacy_id',
        text: 'goal.legacy_id',
        routeParam: 'id',
        template: (item: any): string => item.goal.legacy_id,
        getParams(item: any): { id: string } {
            return {
                id: item.goal.id,
            };
        },
        getQuery(item: any): { offer_id: string } {
            return {
                offer_id: item.offer.legacy_id,
            };
        },
    },
    {
        routerName: 'offer-goal',
        slot: 'item.goal.name',
        text: 'goal.name',
        routeParam: 'id',
        template: (item: any): string => item.goal.name,
        getParams(item: any): { id: string } {
            return {
                id: item.goal.id,
            };
        },
        getQuery(item: any): { offer_id: string } {
            return {
                offer_id: item.offer.legacy_id,
            };
        },
    },
];
