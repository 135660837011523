import { defineStore } from "pinia";

export const useOfferCardStore = defineStore("offerCard", {
    state: () => ({
        activeEditId: '',
    }),
    actions: {
        setActiveEdit(id: string) {
            this.activeEditId = id;
        },
        resetActiveEdit() {
            this.activeEditId = '';
        },
    },
});
