import { IColumnFilter, IFilter } from "@/types";
import { translateBoolean, translateHasReadyParamsFilter, translateOffersStatus } from "@/utils/translate";
import { CreatedFilters } from "@/services/filters/filters";
import { CreatedColumns } from "@/services/columns/columns";
import {
    getEmployeesCampaigns, getEmployeesContests, getEmployeesCountries,
    getEmployeesDepartments, getEmployeesOfferVerticals,
    getEmployeesPayouts,
    getEmployeesProducts,
    getEmployeesStats, getEmployeesTargetActions, getEmployeesTraffics, getOfferTags, getEmployeesOffersV2,
} from "@/api/offers";
import { getAdvertisersSearch } from "@/api/revenue";
import { filterClass } from '@/services/filters/filterClass';
import { USER_ROLES } from "@/mappings/user-roles";
import store from "@/store";
import { showServerError } from "@/utils";
import { getActiveUserSearch } from "@/api/user";
import { IUser } from "@/api/types/user";

export class OffersFilters extends CreatedFilters {
    // текущий юзер
    user = store.getters['authModule/GET_USER'];
    loadingDataForSelect = [
        { id: 'department', errorName: 'Список направлений не загружен', api: getEmployeesDepartments },
        { id: 'payout', errorName: 'Список типов выплат не загружен', api: getEmployeesPayouts },
        { id: 'product', errorName: 'Список типов продуктов не загружен', api: getEmployeesProducts },
        { id: 'stats', errorName: 'Список типов статистики не загружен', api: getEmployeesStats },
        { id: 'traffic', errorName: 'Список типов трафика не загружен', api: getEmployeesTraffics },
        { id: 'target_action', errorName: 'Список целевых действий не загружен', api: getEmployeesTargetActions },
        { id: 'vertical', errorName: 'Список вертикалей офферов не загружен', api: getEmployeesOfferVerticals },
        { id: 'country', errorName: 'Список стран не загружен', api: getEmployeesCountries },
        { id: 'tag', errorName: 'Список тегов не загружен', api: getOfferTags },
        { id: 'contest', errorName: 'Список конкурсов не загружен', api: getEmployeesContests },
        { id: 'campaign', errorName: 'Список акций не загружен', api: getEmployeesCampaigns },
    ];

    constructor() {
        super();
        (async () =>  {
            for (const select of this.loadingDataForSelect) {
                await this.loadDataForSelect(select.api, select.id, select.errorName);
            }
        })();
        // проставляем текущего юзера
        if (this.isSetUser) this.setUser();
    }

    async loadDataForSelect(apiMethod: any, filterId: string, errorName: string = 'Список не загружен',
    ): Promise<void> {
        const filter = this.filters.find((fl) => fl.id === filterId);
        if (!filter) {return;}
        try {
            filter.items = await apiMethod();
        } catch (err) {
            showServerError(err, errorName);
        }
    }

    get isAccountManager(): boolean {
        return this.user?.roles?.includes(USER_ROLES.ACCOUNT_MANAGER);
    }

    get isSetUser(): boolean {
        return this.isAccountManager && (this.lengthQueryFilters <= 0 );
    }

    setUser(): void {
        const filter =  this.filters.find(f=> f.id === 'manager_id');
        if (!filter) return;

        const value = {
            id: this.user.account_id,
            account_id: this.user.account_id,
            name: `${this.user.account_id} ${this.user.first_name} ${this.user.last_name}`,
        };

        filter!.select = [value];
        filter!.items!.push(value);
    }
    getFilters(): IFilter[] {
        const advertiser = store.getters['revenueModule/GET_CURRENT_ADVERTISER'];
        const isAdvertiser = advertiser && this.checkRoute('advertiser');
        return [
            new filterClass.Multiselect({
                col: 1,
                id: 'status',
                label: 'Статус',
                type: 'string',
                select: ['active'],
                defaultValue: ['active'],
                items: [
                    { id: 'active' },
                    { id: 'paused' },
                    { id: 'deleted' },
                    { id: 'pending' },
                    { id: 'expired' },
                    { id: 'capped' },
                ],
                itemValue: 'id',
                template: ({ id }) => this.translateStatus(id, translateOffersStatus),
                itemText: ({ id }) => this.translateStatus(id, translateOffersStatus),
            }),
            new filterClass.Autocomplete({
                col: 1,
                id: 'manager_id',
                apiMethod: getActiveUserSearch,
                label: 'Менеджер',
                itemValue: 'id',
                template: (item) => `${item.id} ${item.first_name} ${item.last_name}`,
                itemText: 'name',
                select: this.isAccountManager ? [`${this.user.account_id} ${this.user.first_name} ${this.user.last_name}`] : [],
                items: [],
                multiple: true,
            }),
            new filterClass.Select({
                col: 1,
                id: 'private',
                label: 'Приватный',
                items: ['true', 'false'],
                hasOptions: true,
                clearable: true,
                remove: true,
                select: '',
                placeholder: '',
                translate: translateBoolean,
            }),
            new filterClass.Select({
                col: 1,
                id: 'ready_for_erir',
                label: 'Требует маркировки',
                items: ['true', 'false'],
                select: '',
                placeholder: '',
                clearable: true,
                remove: true,
                translate: translateBoolean,
            }),
            new filterClass.Multiselect({
                col: 1,
                label: 'Направление',
                id: 'department',
                type: 'number',
                select: [],
                items: [],
                itemValue: 'id',
                itemText: 'name',
                template: this.getTemplate,
                noDataText: 'Нет направлений',
            }),
            new filterClass.Combobox({
                col: 1,
                id: 'country',
                type: 'number',
                select: [],
                items: [],
                label: 'Страна оффера',
                itemValue: 'id',
                itemText: 'name',
                template: this.getTemplate,
                noDataText: 'Нет списка стран',
            }),
            new filterClass.Multiselect({
                col: 1,
                label: 'Выплата',
                id: 'payout',
                select: [],
                items: [],
                type: 'number',
                itemValue: 'id',
                itemText: 'name',
                template: this.getTemplate,
                noDataText: 'Нет выбранных выплат',
            }),
            new filterClass.Multiselect({
                col: 1,
                label: 'Теги',
                id: 'tag',
                select: [],
                items: [],
                type: 'number',
                itemValue: 'id',
                itemText: 'name',
                template: this.getTemplate,
                noDataText: 'Нет выбранных тегов офферов',
            }),
            new filterClass.Multiselect({
                col: 1,
                label: 'Конкурс',
                id: 'contest',
                select: [],
                items: [],
                type: 'number',
                itemValue: 'id',
                itemText: 'name',
                template: this.getTemplate,
                noDataText: 'Нет выбранных конкурсов',
            }),
            new filterClass.Select({
                col: 1,
                id: 'has_ready_params',
                label: 'Наличие параметров продукта',
                items: ['true', 'false'],
                select: '',
                placeholder: '',
                clearable: true,
                remove: true,
                translate: translateHasReadyParamsFilter,
            }),
            new filterClass.Datepicker({
                col: 1,
                id: 'created_at',
                label: 'Дата создания',
                pickerType: 'date',
                select: [],
                range: true,
                clearable: true,
                isTimeAdder: true,
                defaultTime: '00:00:00',
            }),
            new filterClass.Multiselect({
                col: 2,
                label: 'Тип продукта',
                id: 'product',
                select: [],
                items: [],
                type: 'number',
                itemValue: 'id',
                itemText: 'name',
                template: this.getTemplate,
                noDataText: 'Нет выбранных типов продукта',
            }),
            new filterClass.Multiselect({
                col: 2,
                label: 'Тип трафика',
                id: 'traffic',
                select: [],
                items: [],
                type: 'number',
                itemValue: 'id',
                itemText: 'name',
                template: this.getTemplate,
                noDataText: 'Нет выбранных типов трафика',
            }),
            new filterClass.Multiselect({
                col: 2,
                label: 'Статистика',
                id: 'stats',
                select: [],
                items: [],
                type: 'number',
                itemValue: 'id',
                itemText: 'name',
                template: this.getTemplate,
                noDataText: 'Нет выбранных типов статистики',
            }),
            new filterClass.Autocomplete({
                col: 2,
                id: 'legacy_id',
                apiMethod: getEmployeesOffersV2,
                key: 'search',
                label: 'Офферы',
                itemValue: 'legacy_id',
                itemText: 'name',
                select: [],
                items: [],
                multiple: true,
                multiPaste: true,
                supValue: 'legacy_id',
            }),
            new filterClass.Autocomplete({
                col: 2,
                id: 'advertiser_legacy_id',
                apiMethod: getAdvertisersSearch,
                label: 'Рекламодатель',
                itemValue: 'id',
                itemText: 'name',
                select: isAdvertiser ? [advertiser] : [],
                defaultValue: isAdvertiser ? [advertiser] : [],
                items: [],
                multiple: true,
                isHidden: isAdvertiser,
            }),
            new filterClass.Multiselect({
                col: 2,
                label: 'Целевое действие',
                id: 'target_action',
                select: [],
                items: [],
                type: 'number',
                itemValue: 'id',
                itemText: 'name',
                template: this.getTemplate,
                noDataText: 'Нет выбранных целевых действий',
            }),
            new filterClass.Multiselect({
                col: 2,
                label: 'Вертикаль',
                id: 'vertical',
                select: [],
                items: [],
                type: 'number',
                itemValue: 'id',
                itemText: 'name',
                template: this.getTemplate,
                noDataText: 'Нет выбранных вертикалей офферов',
            }),
            new filterClass.Select({
                col: 2,
                id: 'has_toprec_logo',
                label: 'Доп. логотип',
                items: ['false', 'true'],
                clearable: true,
                remove: true,
                select: '',
                placeholder: '',
                translate: (i) => i === 'true' ? 'Добавлен' : 'Отсутствует',
            }),
            new filterClass.Multiselect({
                col: 2,
                label: 'Акция',
                id: 'campaign',
                select: [],
                items: [],
                type: 'number',
                itemValue: 'id',
                itemText: 'name',
                template: this.getTemplate,
                noDataText: 'Нет выбранных акций',
            }),
            new filterClass.Select({
                col: 2,
                id: 'has_positive_metrics',
                label: 'Наличие метрики',
                items: ['false', 'true'],
                clearable: true,
                remove: true,
                select: '',
                placeholder: '',
                translate: (i) => i === 'true' ? 'Есть' : 'Отсутствует',
            }),
            new filterClass.Select({
                col: 2,
                id: 'has_round_logo',
                label: 'Круглый логотип',
                items: ['false', 'true'],
                clearable: true,
                remove: true,
                select: '',
                placeholder: '',
                translate: (i) => i === 'true' ? 'Заполнен' : 'Не заполнен',
            }),
            new filterClass.Checkbox({
                col: 2,
                label: 'Чеккер',
                id: 'is_checker_used',
                select: [],
                itemText: 'true',
                type: 'string',
                checkbox: true,
            }),
            new filterClass.Checkbox({
                col: 1,
                label: 'Лиды из кликов',
                id: 'offer_for_clicks',
                select: [],
                itemText: 'true',
                type: 'string',
                checkbox: true,
            }),
        ];
    }

    getTemplate(item: any): string {
        return item.name;
    }

    clearFilters(): void {
        const user: IUser = store.getters['authModule/GET_USER'];
        if (this.isAccountManager) {
            store.commit('SET_DEFAULT_FILTERS_PARAMS_OBJECT', { manager_id: user.account_id });
        }
        super.clearFilters();
        if (this.isAccountManager) this.setUser();
    }

    setDefaultFilters(): any {
        if (store.state.isWithoutDefaultFilters) {
            this.preventSetDefaultFilters();
            return;
        }
        const query = this.getQueryFilters();
        const activeFilters: object[] = [];
        this.filters.forEach(filter => {
            if (filter.select) {
                if (filter.id === 'manager_id' && Object.keys(filter.select).length > 0) {
                    activeFilters.push({ [filter.id]: filter.select[filter.itemValue!] });
                } else if (filter.select.length > 0) {
                    activeFilters.push({ [filter.id]: filter.select });
                }
            }
        });

        const paramsObj = Object.assign({}, ...activeFilters);
        store.commit('SET_DEFAULT_FILTERS_PARAMS_OBJECT', paramsObj);

        if (Object.keys(query).length <= 0) {
            store.commit('SET_FILTERS_PARAMS_OBJECT', paramsObj);
        }
    }

}

export class OffersColumns extends CreatedColumns {
    constructor() {
        super();
    }

    getColumns(): IColumnFilter[] {
        return [
            {
                value: false,
                name: 'Оффер',
                items: [
                    { title: 'ID', value: 'legacy_id' },
                    { title: 'Лого', value: 'logo' },
                    { title: 'Название оффера', value: 'name' },
                    { title: 'Рекламодатель', value: 'advertiser.name' },
                    { title: 'Выплата', value: 'goals_payout' },
                    { title: 'Доход', value: 'goals_revenue' },
                    { title: 'Страны', value: 'countries' },
                    { title: 'Менеджер', value: 'manager.name' },
                    { title: 'Статус', value: 'status' },
                    { title: 'Приватность', value: 'private' },
                    { title: 'Маркировка', value: 'ready_for_erir' },
                    { title: 'Треб. согл.', value: 'confirmations.requires_advertiser_approval' },
                    { title: 'Конкурс', value: 'contests', default: false },
                    { title: 'Акция', value: 'campaigns', default: false },
                    { title: 'Теги', value: 'tags' },
                    { title: 'Preview URL', value: 'preview_url', default: false },
                    { title: 'Срок действия', value: 'expiration_date' },
                ],
            },
            {
                value: false,
                name: 'Категории',
                items: [
                    { title: 'Тип трафика', value: 'traffics', default: false  },
                    { title: 'Направление', value: 'departments', default: false },
                    { title: 'Тип продукта', value: 'products', default: false },
                    { title: 'Целевое действие', value: 'target_actions', default: false },
                    { title: 'Выплата', value: 'payouts', default: false },
                    { title: 'Вертикаль', value: 'verticals', default: false },
                ],
            },
            {
                value: false,
                name: 'Метрики',
                items: [
                    { title: 'CRt', value: 'metrics.cr_t', default: false },
                    { title: 'CRu', value: 'metrics.cr_u' },
                    { title: 'AR', value: 'metrics.ar', default: false },
                    { title: 'EPCt', value: 'metrics.epc_t', default: false },
                    { title: 'EPCu', value: 'metrics.epc_u' },
                    { title: 'EPL', value: 'metrics.epl' },
                ],
            },
        ];
    }

    get getColumnsForAdvertiserDetailPage(): IColumnFilter[] {
        const blockedCols = ['advertiser.name', 'manager.name'];
        return this.getFilteredColumns(blockedCols);
    }
}
