
import { Component, Prop, Ref, Vue } from "vue-property-decorator";
import OfferPeriodSelect from "@/components/offers/offer/OfferPeriodSelect.vue";
import { namespace } from "vuex-class";
import { getFormatDate } from "@/utils/formatDates";
import BaseCard from "@/components/base/BaseCard.vue";
import { IOfferMetric } from "@/api/types/offers";
import { keys } from "lodash-es";
import { getOfferMetricsByPeriod } from "@/api/offers";
import { showServerError } from "@/utils";
import { ICurrency } from "@/api/types/catalogue";
import BaseCheckbox from "@/components_v3/base/formComponents/BaseCheckbox.vue";

const offerStore = namespace('offersModule');
const catalogueStore = namespace('catalogueModule');

interface ISeries {
    data: number[],
    name: string,
    color: string
}

@Component({
    methods: {
        keys,
    },
    components: {
        BaseCard,
        OfferPeriodSelect,
        BaseCheckbox,
    },
})

export default class OfferStatisticChart extends Vue {
    @Prop() offer!: { id: number, name: string, metrics: IOfferMetric[], isAPI: boolean };
    @offerStore.State('statPeriodForList') statPeriod!: number;
    @catalogueStore.State('currencies') currencies!: ICurrency[];
    @Ref() chart: any;

    legend = {};
    dates: { full: string[], short: string[] } = {
        full: [],
        short: [],
    };
    percentMetrics = ['AR', 'CRt', 'CRu'];
    currencyMetrics = ['EPCu', 'EPCt', 'EPL'];
    metricsByPeriod: { [key: string]: IOfferMetric } = {};
    currency: string = '';

    updateDates(): void {
        const dates = Object.keys(this.metricsByPeriod).reverse();
        this.dates.short = dates.map(d => getFormatDate(d, 'dd.MM'));
        this.dates.full = dates.map(d => getFormatDate(d, 'dd.MM.yyyy'));
    }

    handleCheck(value: boolean, index: number): void {
        (this.legend[index] as any) = value;
        this.scrollChartInRight();
    }

    async selectPeriod(period: number = this.statPeriod): Promise<void> {
        try {
            const { data } = await getOfferMetricsByPeriod(this.offer.id, period);
            this.metricsByPeriod = data.metrics;
            this.currency = data.offer.currency;
            this.updateDates();
            this.scrollChartInRight();
        } catch (err) {
            showServerError(err, "Метрики по периоду не загружены");
        }
    }

    async mounted(): Promise<void> {
        await this.selectPeriod();
        this.setLegend();

        this.legend['EPL'] = false;
        this.scrollChartInRight();
    }

    scrollChartInRight(): void {
        const chart = document.querySelectorAll('.offer-chart__chart');
        this.$nextTick(() => {
            chart[0].scrollLeft = chart[0].scrollWidth;
        });
    }

    setLegend(): void {
        const labels = this.getSeries.map(i => i.name);
        this.legend = Object.fromEntries(labels.map(l => this.isHideMetric(l) ? [l, false] : [l, true]));
    }

    isHideMetric(name: string): boolean {
        return ['CRt', 'CRu', 'EPCu', 'EPCt', 'EPL'].includes(name) && this.offer.isAPI;
    }

    get getPeriod(): string {
        return `${this.dates.full[0]} - ${this.dates.full[this.statPeriod - 1]}`;
    }

    get getSeries(): ISeries[] {
        const targetMetrics = [
            { name: 'CRt', key: 'cr_t' },
            { name: 'CRu', key: 'cr_u' },
            { name: 'AR', key: 'ar' },
            { name: 'EPCt', key: 'epc_t' },
            { name: 'EPCu', key: 'epc_u' },
            { name: 'EPL', key: 'epl' },
        ];
        const res = {};
        targetMetrics.forEach(({ name, key }) => {
            const color = this.getColor(name);
            const data: number[] = Object.values(this.metricsByPeriod).map(m => m[key] || 0).reverse();
            res[name] = { name, data, color };
        });
        return Object.values(res);
    }

    get getFilteredSeries(): ISeries[] | [] {
        return this.getSeries.filter(i => !!this.legend[i.name]);
    }

    get getCurrency(): string {
        const cur = this.currencies.find(c => c.code === this.currency);
        return cur ? cur.symbol : '';
    }

    get getAverages(): { [key: string]: string[] } {
        const series = JSON.parse(JSON.stringify(this.getSeries)) as ISeries[] | [];
        const averages = {};
        series.forEach(s => {
            averages[s.name] = (s.data.reduce((a, b) => +a + +b, 0) / this.statPeriod).toFixed(2);
        });
        return averages;
    }

    getColor(legend: string): string {
        switch (legend) {
        case 'CRu':
            return '#FF506F';
        case 'CRt':
            return '#FFAE80';
        case 'AR':
            return '#0078E6';
        case 'EPCu':
            return '#52C0C7';
        case 'EPCt':
            return '#79C677';
        case 'EPL':
            return '#B27FE5';
        default:
            return '#fff000';
        }
    }

    getSymbol(metric: string): string {
        return this.percentMetrics.includes(metric) ? '%' : this.getCurrency;
    }

    getMax(percent: boolean = false): number {
        const series = JSON.parse(JSON.stringify(this.getFilteredSeries)) as ISeries[] | [];
        const names = percent ? this.percentMetrics : this.currencyMetrics;
        const seriesByAxis = series.filter(s => names.includes(s.name));
        const max = Math.max(...seriesByAxis.flatMap(({ data }) => data));
        return Math.ceil(max / 10) * 10;
    }

    get getHeaders(): { text: string, value: string }[] {
        const series = JSON.parse(JSON.stringify(this.getFilteredSeries)) as ISeries[] | [];
        const headers = series.map(s => ({ text: `${s.name}, ${this.getSymbol(s.name)}`, value: s.name }));
        headers.unshift({ text: 'Период', value: 'date' });
        return headers;
    }

    get getItems(): any[] {
        const series = JSON.parse(JSON.stringify(this.getFilteredSeries)) as ISeries[] | [];
        return this.dates.full.map((date, index) => {
            const item: any = { date };
            series.forEach(s => item[s.name] = `${s.data[index].toFixed(2)}`);
            return item;
        }).sort((a: any, b: any) => a < b ? 1 : a > b ? 0 : -1);
    }

    get getYAxis(): { [key: string]: { title: string, steps: number[] } } {
        const percentMax = this.getMax(true);
        const currencyMax = this.getMax();
        const percentAxis = percentMax > 0 ? [0, percentMax / 4, percentMax / 2, percentMax * 0.75, percentMax] : [];
        const currencyAxis = currencyMax > 0 ? [0, currencyMax / 4, currencyMax / 2, currencyMax * 0.75, currencyMax] : [];
        return {
            percent: {
                title: '%',
                steps: percentAxis,
            },
            currency: {
                title: this.getCurrency,
                steps: currencyAxis,
            },
        };
    }

    get getChartWidth(): string {
        return this.statPeriod === 30 ? '1200' : '1030';
    }

    getTooltip({ series, dataPointIndex, w }: any): any {
        const date = this.dates.full[dataPointIndex];
        const templates: string[] = [];
        for (let i = 0; i < series.length; i++) {
            const data = {
                color: w.globals.initialSeries[i].color,
                name: w.globals.initialSeries[i].name,
                value: series[i][dataPointIndex],
            };
            const template = `
                                <div class="offer-chart__tooltip-item">
                                    <span
                                        class="offer-chart__tooltip-item-name"
                                        style="color: ${data.color}"
                                    >
                                        ${data.name} ${this.getSymbol(data.name)}
                                    </span>
                                    <span class="offer-chart__tooltip-item-value">
                                        <div style="width: 10px; height: 10px; border: 1px solid ${data.color}; border-radius: 50%"></div>
                                        ${data.value} ${this.getSymbol(data.name)}
                                    </span>
                                </div>`;
            templates.push(template);
        }

        return `
                                <div class="offer-chart__tooltip">
                                    <span class="offer-chart__tooltip-title">${date}</span>
                                    <div class="offer-chart__tooltip-box">${templates.join(' ')}</div>
                                </div>`;
    }

    get chartOptions(): any {
        return {
            chart: {
                height: '300',
                width: this.getChartWidth,
                type: 'line',
                zoom: {
                    enabled: false,
                },
                toolbar: {
                    show: false,
                },
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                width: 2,
                curve: 'smooth',
            },
            legend: {
                show: false,
            },
            markers: {
                size: 0,
                hover: {
                    sizeOffset: 6,
                },
            },
            xaxis: {
                categories: this.dates.short,
                tooltip: {
                    enabled: false,
                },
            },
            yaxis: {
                show: false,
            },
            grid: {
                borderColor: '#D5DBE3',
                xaxis: {
                    lines: {
                        show: true,
                    },
                },
            },
            tooltip: {
                custom: ({ series, dataPointIndex, w }) => {
                    return this.getTooltip({ series, dataPointIndex, w });
                },
            },
        };
    }
}
