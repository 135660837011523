import Vue from 'vue';
import VueNumerals from 'vue-numerals';
import { ValidationProvider } from 'vee-validate';
import VueTheMask from 'vue-the-mask';
import store from './store';
import { axiosInstance } from '@/api';
import router from '@/router';
import App from '@/App.vue';
import vuetify from '@/plugins/vuetify';
import axios from 'axios';
import { NotificationMessage } from '@/utils/notification';
import { isAuthenticated } from '@/store/modules/auth/getters';
import VueYandexMetrika from 'vue-yandex-metrika';
import { createPinia, PiniaVuePlugin } from "pinia";


// Pinia
Vue.use(PiniaVuePlugin);
const pinia = createPinia();



require('@/plugins/vue-scroll.ts');
require('@/plugins/v-calendar.ts');
require('@/plugins/global-events.client.ts');
require('@/plugins/socket.ts');
// @ts-ignore
Vue.use(VueTheMask);
Vue.use(VueNumerals);
Vue.component('ValidationProvider', ValidationProvider);
export let showModalAxios = true;
const env = process.env.NODE_ENV;
if (env !== 'development') {
    Vue.use(VueYandexMetrika, {
        id: 93342905,
        router,
        env,
        options: {
            webvisor: true,
        },
    });
}

axiosInstance.interceptors.response.use(
    (response) => {
        // показывать модалку с ошибками
        showModalAxios = true;
        return response;
    },
    (error) => {
        // если запрос отменен, модалку с ошибкой не показываем
        if (axios.isCancel(error)) {
            return new Promise(() => {
                showModalAxios = false;
            });
        }
        if (error.response!.status === 401) {
            showModalAxios = false;

            if (router.currentRoute.name === 'sign-in') {
                new NotificationMessage('Неверный логин или пароль', 'error');
            } else {
                return logout();
            }
        } else if (error.response.status === 500) {
            store.commit('SET_SERVER_ERROR', { status: 500, message: 'Упс...Что-то пошло не так' });
        }
        return Promise.reject(error.response);
    },
);

const logout = async () => {
    await store.dispatch('authModule/AUTHORIZATION_LOGOUT_METHOD');
    await router.push('/sign-in');
};

(async function() {
    if (isAuthenticated()) {
        store.commit('authModule/AUTH_SUCCESS');
    } else {
        if (window.location.pathname !== '/sign-in') {
            await logout();
        }
    }
})();

Vue.config.productionTip = false;
new Vue({
    router,
    store,
    vuetify,
    pinia,
    render: (h) => h(App),
}).$mount('#app');
