
import { Vue, Component, Prop, Ref, Watch } from "vue-property-decorator";
import OfferGoals from "@/components/offers/offer/OfferGoals.vue";
import { OfferSingle } from "@/services/offers/OfferSingle";
import Loader from "@/components/base/Loader.vue";
import { eventBus } from "@/eventbus";
import BaseCard from "@/components/base/BaseCard.vue";
import CreateOfferGoal from "@/components/offers/createOffer/createOfferGoal.vue";
import SvgPlus from "@/assets/icons/plus.svg";
import OfferGoalsCreate from "@/components/offers/offer/OfferGoalsCreate.vue";

@Component({
    components: { OfferGoalsCreate, SvgPlus, CreateOfferGoal, BaseCard, Loader, OfferGoals },
})

export default class OfferGoalsTab extends Vue {
    @Prop({ required: true }) offer!: OfferSingle;
    @Prop({ default: false }) isEditOnMounted!: boolean;
    @Ref() goalsComp!: OfferGoals;

    isEdit: boolean = false;
    isLoading: boolean = false;
    isCreateGoals: boolean = false;

    mounted(): void {
        if (this.isEditOnMounted) this.isEdit = true;
    }

    async editInfo(isEdit: boolean): Promise<void> {
        if (!isEdit) {
            this.isLoading = true;
            const isUpdate = await this.offer.editGoals(this.goalsComp.goalsItems);
            isUpdate && eventBus.$emit("update-offer");
            this.isLoading = false;
        }

        this.isEdit = isEdit;
    }

    cancelEdit(): void {
        this.isEdit = false;
        this.goalsComp.goalsItems = this.offer.goals;
    }

    enableCreateMode(): void {
        this.isCreateGoals = true;
        const params = { ...this.$route.query, is_create_goals: true };
        this.offer.updateQueryString(params);
    }

    disableCreateMode(): void {
        this.isCreateGoals = false;
        this.$router.replace({ query: { 'tab': '1' } });
        setTimeout(() => {
            this.goalsComp.filterStatus.select = '';
            this.goalsComp.status = '';
        }, 100);
    }

    @Watch('$route.query')
    checkMode(): void {
        if (!this.$route.query['is_create_goals']) {
            this.isCreateGoals = false;
        }
    }
}

