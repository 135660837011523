import {
    IChangesHistoryItem,
    IEditableCaps,
    IEditableOfferFields,
    IOffer,
    IOfferCaps, IOfferErid,
    IOfferGoal,
    IOfferMetric,
    IOfferNote,
    IOfferV2,
    IPatchOfferGoal,
    IPlannedChangesItem,
    IRedirectToOffer,
    ITrackingDomain,
} from "@/api/types/offers";
import { Offer } from "@/services/offers/Offer";
import { showNotification, showServerError } from "@/utils";
import router from "@/router";
import {
    deleteOffersNote,
    deletePlannedChanges,
    editOneEmployeesOffer,
    getChangeHistory,
    getEmployeesDepartments,
    getEmployeesOffersV2,
    getEmployeesOfferVerticals,
    getEmployeesPayouts,
    getEmployeesProducts,
    getEmployeesStats, getEmployeesTargetActions,
    getEmployeesTraffics,
    getOfferNotes, getOfferTags,
    getOfferTrackingDomainsList,
    getOfferTrackingList,
    getParamsForm,
    getPlannedChanges,
    getRedirectsToOffer,
    patchOffersNote,
    patchPlannedChanges,
    patchRedirectOffer,
    postOffersNote,
    saveOfferTags,
    updateOfferGoal,
} from "@/api/offers";
import isEqual from "lodash-es/isEqual";
import store from "@/store";
import { translateOffersStatus } from "@/utils/translate";
import { getFormatDate } from "@/utils/formatDates";
import { getOneAdvertiser } from "@/api/revenue";
import { OFFER_PRODUCT_PARAMS } from "@/mappings/offer";
import intersection from "lodash-es/intersection";
import { getOfferErid } from "@/api/leadgid-ord";
import { getCurrentDate } from '@/utils/formatDates';

export class OfferSingle extends Offer {
    approve_conversions: boolean = false;
    currency = '';
    uniqKey = 0;
    caps = {} as IOfferCaps;
    confirmations: {
        requires_advertiser_approval: boolean;
        requires_approval: boolean;
    } = {
            requires_advertiser_approval: false,
            requires_approval: false,
        };
    deep_links_enabled: number = 0;
    description_ru: string = '';
    description_en: string = '';
    short_description_ru : string = '';
    short_description_en : string = '';
    advantages : string = '';
    disadvantages : string = '';
    endpoint?: boolean = false;
    enforce_geo_targeting?: boolean | null = null;
    enforce_secure_tracking_link = false;
    history_changes: IChangesHistoryItem[] = [];
    is_active: number = 0;
    last_lead_date = '';
    last_report_date = '';
    logo_round: string | null = null;
    metrics?: IOfferMetric = {
        ar: 0,
        cr_t: 0,
        cr_u: 0,
        epc_t: 0,
        epc_u: 0,
        epl: 0,
    };
    multiple_conversions: number = 0;
    non_unique_click_attribution: 'disabled' | 'reject' = 'reject';
    notes: IOfferNote[] = [];
    planned_changes: IPlannedChangesItem[] = [];
    preview_url: string = '';
    private: number = 0;
    proactive_click_fraud_prevention: 'allow' | 'reject' = 'reject';
    ready_for_erir: number = 0;
    redirectOffer: IOffer | null = null;
    redirect_offer_id: number | null | undefined = null;
    redirectsToOffer: IRedirectToOffer[] = [];
    requires_approval: number = 0;
    session_hours: number = 0;
    tracking_domain?: ITrackingDomain;
    erid: IOfferErid = {} as IOfferErid;
    offerParamsForms?: any = {};
    created_at: string = "";
    private translatedParamsFieldsAndValues: { [key: string]: string } = {};
    offer_for_clicks: boolean = false;

    constructor(offer: IOfferV2) {
        // @ts-ignore
        // потом список перейдет V2
        super(offer);
        if (offer !== undefined) {
            this.approve_conversions = offer.approve_conversions;
            this.caps = offer.caps ? { ...offer.caps } : offer.caps;
            this.created_at = offer.created_at;
            this.confirmations.requires_advertiser_approval = !!offer.confirmations!.requires_advertiser_approval;
            this.confirmations.requires_approval = !!offer.confirmations!.requires_approval;
            this.deep_links_enabled = offer.deep_links_enabled;
            this.description_ru = offer.description_ru;
            this.description_en = offer.description_en;
            this.short_description_ru = offer.short_description_ru;
            this.short_description_en = offer.short_description_en;
            this.advantages = offer.advantages;
            this.disadvantages = offer.disadvantages;
            this.enforce_geo_targeting = offer.enforce_geo_targeting;
            this.enforce_secure_tracking_link = offer.enforce_secure_tracking_link;
            this.is_active = offer.is_active;
            this.last_lead_date = offer.last_report_date || '';
            this.last_report_date = offer.last_report_date || '';
            this.logo_round = offer.logo_round || null;
            this.metrics = offer.metrics;
            this.multiple_conversions = offer.multiple_conversions;
            this.non_unique_click_attribution = offer.non_unique_click_attribution;
            this.preview_url = offer.preview_url;
            this.private = offer.private;
            this.proactive_click_fraud_prevention = offer.proactive_click_fraud_prevention;
            this.ready_for_erir = offer.ready_for_erir;
            this.redirect_offer_id = offer.redirect_offer_id!;
            this.session_hours = offer.session_hours;
            this.tracking_domain = offer.tracking_domain;
            this.tracking_protocol = offer.tracking_protocol;
            this.currency = offer.currency;
            this.offer_for_clicks = offer.offer_for_clicks;
        }
    }

    get isGoalsEmpty(): boolean {
        return this.goals?.length <= 0;
    }

    get isExpired(): boolean {
        return new Date(this.expiration_date!)! < new Date();
    }

    get isDisabledOtherCards(): boolean {
        return new Date(this.expiration_date!.split(' ')[0]) <= new Date(getCurrentDate('yyyy-MM-dd'));
    }

    async downloadOfferData(): Promise<void> {
        await Promise.all([
            this.getNotes(),
            this.getRedirectOffer(),
            this.getRedirectToOffer(),
            this.getPlannedChanges(),
            this.getErid(),
            this.getForm(),
            this.getSelectItems(),
        ]);
    }

    private async getSelectItems(): Promise<void> {
        const properties = [
            { property: 'tracking_protocol', api: getOfferTrackingList },
            { property: 'tracking_domain', api: getOfferTrackingDomainsList },
            { property: 'verticals', api: getEmployeesOfferVerticals },
            { property: 'departments', api: getEmployeesDepartments },
            { property: 'traffics', api: getEmployeesTraffics },
            { property: 'products', api: getEmployeesProducts },
            { property: 'payouts', api: getEmployeesPayouts },
            { property: 'stats', api: getEmployeesStats },
            { property: 'target_actions', api: getEmployeesTargetActions },
        ];

        const currentTime = Date.now();
        const oneHour = 60 * 60 * 1000;
        const isUpdateNow = currentTime > store.state['offersModule'].nextUpdateSelectItems;
        const requestParams= isUpdateNow ? properties : properties.filter(prop => !store.state['offersModule'].selects_items[prop.property]);

        //теги могут обновляться в любой момент, поэтому всегда их запрашиваем
        requestParams.push({ property: 'tags', api: getOfferTags });
        await store.dispatch('offersModule/GET_SELECTS_ITEMS', requestParams);
        store.commit('offersModule/SET_NEXT_UPDATE_SELECT_ITEMS', currentTime + oneHour);
    }

    async getNotes(): Promise<void> {
        try {
            const notes = await getOfferNotes(this.legacy_id as number);
            this.setNotes(notes);
        } catch (err) {
            showServerError(err, 'Заметки не загружены');
        }
    }

    async getErid(): Promise<void> {
        try {
            const { data } = await getOfferErid(this.legacy_id as number);
            this.erid = data;
        } catch (err) {}
    }

    async getRedirectToOffer(): Promise<void> {
        try {
            const { data } = await getRedirectsToOffer(this.legacy_id!);
            this.redirectsToOffer = data;
        } catch (err) {
            showServerError(err, 'Редиректы на оффер не найдены');
        }
    }

    async deleteRedirectToOffer(id: number): Promise<void> {
        try {
            await this.setRedirect(null, id);
            await this.getRedirectToOffer();
            showNotification('Редирект успешно удалён');
        } catch (err) {
            showServerError(err, 'Редирект не удалён');
        }
    }

    get availableProducts(): any {
        const offerProductIds = this.products?.map(i => i.id) || [];
        const intersectionArray = intersection(Object.values(OFFER_PRODUCT_PARAMS), offerProductIds);
        return intersectionArray.map(i => Number(i));
    }

    private translateAllProductParams(form): any {
        const translate = (items, parentName?) => {
            if (Array.isArray(items)) {
                items.forEach(item => {
                    if (item.param) {
                        if ((item.param.includes('_from') || item.param.includes('_to')) && parentName) {
                            this.translatedParamsFieldsAndValues[item.param] = `${parentName}, ${(item.name).toLowerCase()}`;
                        } else {
                            this.translatedParamsFieldsAndValues[item.param] = item.name;
                        }
                    }
                    if (item.options) {
                        Object.assign(this.translatedParamsFieldsAndValues, item.options);
                    }
                    if (item.params) {
                        translate(item.params, item.name);
                    }
                });
            }
        };

        for (const key in form) {
            translate(form[key].params);
        }
    }

    async getForm(): Promise<void> {
        for (const id of this.availableProducts) {
            try {
                this.offerParamsForms[id] = await getParamsForm(id);
                this.translateAllProductParams(this.offerParamsForms[id]);
            } catch (err) {
                showServerError(err, 'Параметры оффера не загружены');
            }
        }
    }

    async getRedirectOffer(): Promise<void> {
        if (!this.redirect_offer_id) return;
        try {
            const { data } = await getEmployeesOffersV2({ search: this.redirect_offer_id });
            if (data?.length > 0) {
                this.redirectOffer = data.find(i => i.legacy_id === this.redirect_offer_id) || null;
            }
        } catch (err) {
            showServerError(err, 'Оффер для редиректа не найден');
        }
    }

    get fullNameRedirectOffer(): string {
        return this.redirectOffer ? this.redirectOffer.legacy_id + ' ' + this.redirectOffer.name : '';
    }

    async setRedirect(id: number | null, targetId: number = this.id!): Promise<boolean> {
        try {
            await patchRedirectOffer(targetId, id);
            return true;
        } catch (e) {
            showServerError(e, 'Редирект не изменен');
            return false;
        }
    }

    async saveTags(newTags: number[]): Promise<boolean> {
        const oldTags = this.tags.map(t => +t.id);
        const isEqual = oldTags.length === newTags.length && oldTags.every((el, i) => el === newTags[i]);
        if (isEqual) return false;
        try {
            await saveOfferTags({ tags: newTags }, this.legacy_id!);
            showNotification('Теги успешно сохранены');
            return true;
        } catch (err) {
            showServerError(err, 'Ошибка сохранения тегов');
            return false;
        }
    }

    async getPlannedChanges(is_done: 0 | 1 = 0): Promise<void> {
        try {
            const { data } = await getPlannedChanges(this.legacy_id!, { is_done });
            this.planned_changes = data;
        } catch (err) {
            showServerError(err, 'Ошибка загрузки запланированных изменений');
        }
    }

    async deletePlannedChanges(task_id: number): Promise<void> {
        try {
            await deletePlannedChanges(task_id);
            await this.getPlannedChanges();
        } catch (err) {
            showServerError(err, 'Ошибка загрузки запланированных изменений');
        }
    }

    async patchPlannedChanges(task_id: number, dto: { action_date: string, value: string }): Promise<void> {
        try {
            await patchPlannedChanges(task_id, dto);
            await this.getPlannedChanges();
        } catch (err) {
            showServerError(err, 'Ошибка загрузки запланированных изменений');
        }
    }

    async getHistoryChanges(currentPage: number = 0): Promise<number> {
        let total = 0;
        try {
            const { data, meta } = await getChangeHistory(this.legacy_id!, {
                limit: 100,
                offset: currentPage,
            });
            total = meta?.total!;
            let isAdvertiserChanged = false;
            this.history_changes = data.filter(item => {
                const blockedFields = ['logo_id'];
                return !blockedFields.includes(item.field);
            }).map(item => {
                item.field = item.field === 'protocol' ? 'tracking_protocol' : item.field;
                if (item.field === 'advertiser') isAdvertiserChanged = true;
                return {
                    ...item,
                    translated_filed: this.translateField(item.field, item.subject === 'OfferNote' ? item.subject : undefined),
                    translated_subject: this.translateField(item.subject),
                    subject_id: this.translateSubjectId(item),
                    old_value: this.translateValues(item, 'old_value'),
                    new_value: this.translateValues(item, 'new_value'),
                    changed_by: item.changed_by === 'system' ? 'Системный пользователь' : item.changed_by,
                };
            });
            if (!isAdvertiserChanged) return total;

            const advertiserEntries = this.history_changes.filter(i => i.field === 'advertiser');
            for (const entry of advertiserEntries) {
                if (!entry || typeof entry.old_value !== 'number' || typeof entry.new_value !== 'number') continue;
                const [oldAdvertiser, newAdvertiser] = await Promise.all([
                    this.getAdvertiserData(entry.old_value),
                    this.getAdvertiserData(entry.new_value),
                ]);
                entry.old_value = oldAdvertiser;
                entry.new_value = newAdvertiser;
            }

        } catch (err) {
            showServerError(err, 'Ошибка загрузки истории изменений');
        }
        return total;
    }

    async getAdvertiserData(id: number): Promise<any> {
        try {
            return getOneAdvertiser(id);
        } catch (err) {
            showServerError(err, 'Ошибка загрузки рекламодателя');
        }
    }

    private translateSubjectId(item: IChangesHistoryItem): number {
        if (item.subject === 'Offer') {
            return this.legacy_id!;
        } else if (item.subject === 'Goal') {
            const goal = this.goals.find(g => g.id === item.subject_id);
            return goal?.legacy_id!;
        } else if (item.subject === 'URL') {
            const url = this.additional_urls?.find(u => u.id === item.subject_id);
            return url?.legacy_id! as number;
        } else {
            return item.subject_id;
        }
    }

    findGoalId(legacyId: number): number {
        return this.goals.find(g => g.legacy_id === legacyId)?.id!;
    }

    private translateValues(item: IChangesHistoryItem, propName: string, recursiveKey?: string, recursiveValue?: string | number | boolean): any {
        const value = recursiveKey ? recursiveValue : item[propName];
        const field = recursiveKey ? recursiveKey : item.field;
        if (value === '' || (Array.isArray(value) && value.length === 0)) return '';

        const selectValues = ['verticals', 'departments', 'traffics', 'products', 'payouts', 'stats', 'target_actions', 'countries', 'tags'];
        if (selectValues.includes(field)) {
            const items = field === 'countries' ? store.state['catalogueModule'].countries : store.state['offersModule'].selects_items[field];
            const id = field === 'countries' ? 'code' : 'id';
            const rawValues = value || [];
            return rawValues.map(v => items.find(i => i[id] === v)['name']).join(', ');
        }
        if (['tracking_protocol', 'tracking_domain'].includes(field)) {
            const items = store.state['offersModule'].selects_items[field];
            const id = field === 'tracking_protocol' ? 'type' : 'id';
            const name = field === 'tracking_protocol' ? 'name' : 'domain';
            return items.find(i => i[id] === value)?.[name];
        }
        if (item.subject === 'OfferParams') {
            const newValue = {};
            for (const name in value) {
                const field = this.translatedParamsFieldsAndValues[name] || name;
                const rawValue = value[name];

                if (name.includes('_unit')) continue;
                if (name.includes('_from') || name.includes('_to')) {
                    const key = name.replace('_from', '').replace('_to', '');
                    const unit = this.translatedParamsFieldsAndValues[value[`${key}_unit`]];
                    newValue[field] = (this.translatedParamsFieldsAndValues[rawValue] || rawValue) + (unit ? ` ${unit}` : '');
                } else if (!isNaN(Number(rawValue))) {
                    newValue[field] = rawValue;
                } else if (Array.isArray(rawValue)) {
                    newValue[field] = rawValue.map(v => this.translatedParamsFieldsAndValues[v] || v).join(', ');
                } else {
                    newValue[field] = this.translatedParamsFieldsAndValues[rawValue] || rawValue;
                }
            }
            return newValue;
        }
        if (item.subject === 'Goal' && field === 'all' && !recursiveKey) {
            const newValue = {};
            for (const name in value) {
                const key = name === 'protocol' ? 'tracking_protocol' : name;
                newValue[this.translateField(key)] = this.translateValues(item, propName, key, value[name]);
            }
            return newValue;
        }
        if (['payout.type', 'revenue.type'].includes(field || recursiveKey!)) {
            return value === 'fixed' ? 'Фикс' : 'Процент';
        }
        if (['payout.amount', 'revenue.amount'].includes(field)) {
            return parseFloat(value).toFixed(2);
        }
        if (field === 'expiration_date') {
            return getFormatDate(value);
        }
        if (field === 'active') {
            return value ? 'Активна' : 'Не активна';
        }
        if (field === 'is_deleted') {
            if (item.subject === 'OfferNote') {
                return value ? 'Да' : 'Нет';
            }
            return 'Удаление';
        }
        if (field === 'status') {
            return translateOffersStatus(value).text;
        }
        if (field === 'non_unique_click_attribution') {
            return value === 'allow' ? 'Да' : 'Нет';
        }
        if (field === 'proactive_click_fraud_prevention') {
            return value === 'disabled' ? 'Да' : 'Нет';
        }
        if (['description', 'short_description'].includes(field)) {
            return value.replaceAll('&nbsp;', '');
        }
        if (typeof value === 'boolean') {
            const val = field === 'approve_conversions' ? !value : value;
            return val ? 'Да' : 'Нет';
        }
        return value;
    }

    private prepareCaps(caps: IEditableCaps): IEditableCaps {
        for (const i in caps) {
            if (i.includes('payout') || i.includes('revenue')) {
                caps[i] = Math.floor(Number(caps[i]) * 100) / 100;
            }
        }
        return caps;
    }

    private isEqualCaps(caps: IEditableCaps): boolean {
        const keys = Object.keys(caps);
        return keys.every(key => {
            const oldKeys = key.split('_');
            const oldValue = this.caps ? key === 'enabled' ? this.caps[key] : this.caps[oldKeys[0]][oldKeys[1]] : null;
            return isEqual(caps[key], oldValue);
        });
    }

    async editOffer(data: IEditableOfferFields): Promise<boolean> {
        const currStatus = data.status;
        const arrayValues = ['verticals', 'departments', 'traffics', 'products', 'payouts', 'stats', 'target_actions'];
        const confirmationsValues = ['requires_approval', 'requires_advertiser_approval'];
        const isEqualValues = (key: string): boolean => {
            let sup = '';
            if (confirmationsValues.includes(key)) sup = 'confirmations';
            const oldD = sup ? this[sup][key] : this[key];
            const newD = data[key];
            if (key === 'tracking_protocol') return newD === oldD?.type;
            if (key === 'countries') return isEqual(newD, oldD.map(i => i.code));
            if (arrayValues.includes(key)) return isEqual(newD, oldD.map(i => i.id));
            if (['tracking_domain'].includes(key)) return newD === oldD?.id;
            if (key === 'advertiser') return newD === oldD?.legacy_id;
            if (typeof newD === 'boolean' && key === 'approve_conversions') return newD !== !!oldD;
            if (typeof newD === 'boolean') return newD === !!oldD;
            if (key === 'caps' && data.caps) return this.isEqualCaps(data.caps);
            return newD === oldD;
        };
        const keys = Object.keys(data);
        keys.forEach(key => {
            if (isEqualValues(key)) delete data[key];
        });

        if (!data.status) {
            data.status = currStatus;
        }

        if (Object.keys(data).length === 0) return false;
        if (data.hasOwnProperty('enforce_geo_targeting')) data.enforce_geo_targeting = (data.enforce_geo_targeting === null) ? null : !!data.enforce_geo_targeting;
        if (data.expiration_date) data.expiration_date += ' 23:59:59';
        if (data.hasOwnProperty('action_date') && !data.status) delete data.action_date;
        if (data.hasOwnProperty('approve_conversions')) data.approve_conversions = !data.approve_conversions;
        if (data.caps) data.caps = this.prepareCaps(data.caps);
        if (Object.keys(data).length === 0) return false;
        try {
            await editOneEmployeesOffer(this.legacy_id!, data);
            showNotification('Оффер успешно обновлён');
            return true;
        } catch (err) {
            showServerError(err, 'Ошибка обновления оффера');
            return false;
        } finally {
            if (data.currency) {
                setTimeout(() => {
                    const route = router.resolve({
                        name: 'offer',
                        params: { id: this.legacy_id!.toString() },
                        query: { tab: '6', status: 'paused', offer_legacy_id: this.legacy_id!.toString() },
                    });
                    window.open(route.href, '_blank');
                }, 1000);
            }
        }
    }

    async editGoals(goals: IOfferGoal[]): Promise<boolean> {
        const rawData: IOfferGoal[] = [];
        goals.forEach(g => {
            const original = this.goals.find(goal => goal.legacy_id === g.legacy_id);
            JSON.stringify(original) !== JSON.stringify(g) && rawData.push(g);
        });
        if (rawData.length === 0) return false;

        try {
            const data = rawData.map(i => {
                return {
                    offer_id: this.legacy_id!,
                    goal_id: i.legacy_id!,
                    data: {
                        name: i.name,
                        payout_type: i.payout.type,
                        payout_model: i.payout.model,
                        payout: i.payout.amount,
                        revenue_type: i.revenue?.type!,
                        revenue_model: i.revenue?.model!,
                        revenue: i.revenue?.amount!,
                        is_approve_conversions: i.approve_conversions,
                        is_private: i.private,
                        protocol: i.protocol,
                        is_endpoint: i.endpoint,
                        status: i.active! ? 'active' : 'deleted',
                        action_date: i.action_date,
                    },
                };
            });
            await Promise.all(data.map(({
                offer_id,
                goal_id,
                data,
            }) => updateOfferGoal(offer_id, goal_id, data as IPatchOfferGoal)));
            showNotification('Цели успешно обновлены');
            return true;
        } catch (err) {
            showServerError(err, 'Ошибка обновления цели');
            return false;
        }
    }

    async editGoal(goal: IOfferGoal): Promise<boolean> {
        const original = this.goals.find(g => g.legacy_id === goal.legacy_id);
        if (!original) return false;
        const isEqual = JSON.stringify(original) === JSON.stringify(goal);
        if (isEqual) return false;

        try {
            const updatedGoalData = {
                offer_id: this.legacy_id!,
                goal_id: goal.legacy_id!,
                data: {
                    name: goal.name,
                    payout_type: goal.payout.type,
                    payout_model: goal.payout.model,
                    payout: goal.payout.amount,
                    revenue_type: goal.revenue?.type!,
                    revenue_model: goal.revenue?.model!,
                    revenue: goal.revenue?.amount!,
                    is_approve_conversions: !goal.approve_conversions,
                    is_private: goal.private,
                    protocol: goal.protocol,
                    is_endpoint: goal.endpoint,
                    status: goal.active ? 'active' : 'deleted',
                    action_date: goal.action_date,
                },
            };

            await updateOfferGoal(updatedGoalData.offer_id, updatedGoalData.goal_id, updatedGoalData.data);
            showNotification('Цель успешно обновлена');
            return true;
        } catch (err) {
            showServerError(err, 'Ошибка обновления цели');
            return false;
        }
    }


    updateQueryString(data: any): void {
        const query = { ...data };
        router.replace({ name: router.currentRoute.name || '', query }).catch((error) => {
            if (error.name !== 'NavigationDuplicated') {
                throw error;
            }
        });
    }

    async createNotes(text: string, image: any): Promise<void> {
        try {
            await postOffersNote({ hasoffers_id: this.legacy_id as number, text, image });
            await this.getNotes();
        } catch (err) {
            showServerError(err, 'Заметка не создана');
        }
    }

    setNotes(notes: IOfferNote[]): void {
        this.notes = notes.map(n => new OfferNote(this.legacy_id as number, n));
    }

    translateField(field: string, sup?: string): string {
        switch (field) {
        case ('all'): return 'Создание';
        case ('Offer'): return 'Оффер';
        case ('Goal'): return 'Цель';
        case ('OfferParams' ): return 'Параметры продукта';
        case ('OfferNote' ): return 'Заметка';
        case ('params' ): return 'Параметры';
        case ('active'):
        case ('offer.status'):
        case ('status'):
        case ('goal.status'): return 'Статус';
        case ('advertiser'): return 'Рекламодатель';
        case ('advertiser.is_checker_used'): return 'Чеккер';
        case ('approveConversions'):
        case ('approve_conversions'): return 'Автоподтверждение конверсий';
        case ('caps.daily.conversion'): return 'Лимиты: Конверсий в день';
        case ('caps.daily.payout'): return 'Лимиты: Выплата в день';
        case ('caps.daily.revenue'): return 'Лимиты: Доход в день';
        case ('caps.enabled'): return 'Лимиты';
        case ('caps.lifetime.conversion'): return 'Лимиты: Конверсий всего';
        case ('caps.lifetime.payout'): return 'Лимиты: Выплата всего';
        case ('caps.lifetime.revenue'): return 'Лимиты: Доход всего';
        case ('caps.monthly.payout'): return 'Лимиты: Выплата в месяц';
        case ('caps.monthly.revenue'): return 'Лимиты: Доход в месяц';
        case ('caps.monthly.conversion'): return 'Лимиты: Доход всего';
        case ('confirmations.requires_advertiser_approval'): return 'Требует согласования';
        case ('confirmations.requires_approval'): return 'Требует подтверждения';
        case ('countries'): return 'ГЕО';
        case ('deep_links_enabled'): return 'Deeplink';
        case ('departments'): return 'Направление';
        case ('description'): {
            if (sup === 'OfferNote') return 'Текст заметки';
            return 'Описание оффера';
        }
        case ('short_description'): return 'Краткое описание';
        case ('endpoint'): return 'Endpoint';
        case ('enforce_geo_targeting'): return 'Enforce Geotargeting';
        case ('enforce_secure_tracking_link'): return 'Secure Tracking Links';
        case ('expiration_date'): return 'Дата действия';
        case ('hasoffersId'): return 'HasOffersID';
        case ('logo_id'): return 'ID логотипа';
        case ('logo'): return 'Логотип';
        case ('is_deleted'): return 'Удаление';
        case ('manager'): return 'Менеджер';
        case ('multiple_conversions'): return 'Множественные конверсии';
        case ('name'): return 'Название';
        case ('non_unique_click_attribution'): return 'Non-Unique Click Attribution';
        case ('payout.amount'): return 'Выплата';
        case ('payout.type'): return 'Тип выплаты';
        case ('payout.model'): return 'Модель выплаты';
        case ('payout.currency'): return 'Валюта выплаты';
        case ('payouts'): return 'Выплата';
        case ('preview'):
        case ('url_preview'): return 'Preview URL';
        case ('private'): return 'Приватность';
        case ('proactive_click_fraud_prevention'): return 'Proactive Click Fraud Prevention';
        case ('products'): return 'Тип продукта';
        case ('ready_for_erir'): return 'Подлежит маркировке';
        case ('redirect_offer_id'): return 'Оффер для редиректа';
        case ('revenue.amount'): return 'Доход';
        case ('revenue.type'): return 'Тип дохода';
        case ('revenue.model'): return 'Модель дохода';
        case ('revenue.currency'): return 'Валюта дохода';
        case ('session_hours'): return 'Срок жизни кук';
        case ('stats'): return 'Статистика';
        case ('tags'): return 'Теги';
        case ('target'):
        case ('url_target'): return 'Offer URL';
        case ('target_actions'): return 'Целевое действие';
        case ('top_rec_logo'):
        case ('toprec_logo'):
        case ('toprec__logo'): return 'Доп.логотип';
        case ('round_logo'):
        case ('round__logo'): return 'Круглый логотип';
        case ('tracking_domain'): return 'Трекинговый домен';
        case ('tracking_protocol'): return 'Способ трекинга';
        case ('traffics'): return 'Тип трафика';
        case ('verticals'): return 'Вертикаль';
        default: return field;
        }
    }
}

export class OfferNote implements IOfferNote {
    id: number = 0;
    author: string = '';
    text: string = '';
    created_at: string = '';
    offer_id: number = 0;
    image = '';

    constructor(offerId: number, note: IOfferNote) {
        this.offer_id = offerId;
        if (note !== undefined) {
            this.id = note.id;
            this.author = note.author;
            this.text = note.text;
            this.created_at = note.created_at;
            this.image = note.image;
        }
    }

    async deleteNote(id: number): Promise<void> {
        try {
            await deleteOffersNote(id);
        } catch (err) {
            showServerError(err, 'Ошибка загрузки статистики');
        }
    }

    async patchNote(id: number, data: any): Promise<void> {
        try {
            await patchOffersNote(id, data);
        } catch (err) {
            showServerError(err, 'Заметка не изменена');
        }
    }
}
