
import { Component, Mixins } from "vue-property-decorator";
import TableMixin from "@/mixins/table";
import ListLayout from "@/components/layout/ListLayout.vue";
import { showServerError } from "@/utils";
import { getEmployeeNotifications, getNotificationEvents } from "@/api/notifications";
import NotificationsFilters from "@/components/notifications/NotificationsFilters.vue";
import { IColumnFilter, TableHeader } from "@/types";
import {
    autoNotificationsHeaders,
    defaultAutoColumns,
    defaultColumns,
    NotificationFilters,
    notificationsHeaders, statuses,
    tableIDRoute,
    timeDate,
    uniqueDate,
} from "@/services/TablePage/notifications";
import { eventBus } from "@/eventbus";
import TooltipBtnPinned from "@/components/notifications/TooltipBtnPinned.vue";
import AppTableRouter from "@/components/base/TableUI/AppTableRouter.vue";
import TdRoute from "@/components/base/TableUI/TdRoute.vue";
import { IEvents, INotificationCategory, INotificationList } from "@/api/types/notifications";
import intersection from "lodash-es/intersection";
import PageTabs from "@/components/base/PageTabs.vue";
import CreateBtn from "@/components/base/buttons/CreateBtn.vue";
import BaseText from "@/components_v3/base/BaseText.vue";
import BaseTooltip from "@/components_v3/base/formComponents/BaseTooltip.vue";

@Component({
    components: {
        CreateBtn,
        PageTabs,
        TdRoute,
        AppTableRouter,
        TooltipBtnPinned,
        NotificationsFilters,
        ListLayout,
        BaseText,
        BaseTooltip,
    },
})
export default class NotificationsPage extends Mixins(TableMixin)  {
    isNewDesignPage = true;
    // колонки
    selected: string[] = [];
    // подкатегории
    selectedCategory: string[] = [];
    tabItems = [{ tab: 'Уведомления' }, { tab: 'Автоматические уведомления' }];
    tab: number = 0;
    headers: TableHeader[] = [];
    timeDateTable = timeDate;
    settings = {
        loading:true,
        title:  'Центр уведомлений',
    };
    filters = new NotificationFilters();
    uniqueData = [...uniqueDate];
    statuses = statuses;
    routeID = tableIDRoute;
    events: INotificationCategory[] = [];
    checkboxesCategory: IColumnFilter[] = [];
    isShowCategory = false;

    apiMethod = getEmployeeNotifications;

    changeTab(tab: number, isHistory = true, columns?: any): void {

        this.settings.loading = true;
        this.tab = tab;
        if (isHistory) {
            // меняем возможные заголовки в таблице
            this.getDefaultHeaders();
            // очищаем фильтры
            this.clearAllFilters();
            // меняем список фильтров
            this.getFilters();
            // обновляем урл при переключении табов
            const params = { is_auto: String(!!this.tab), events: this.selectedCategory };
            this.updateQueryString(params);
            this.getNotifications(0);
            eventBus.$emit('go-to-first-page');
        } else {
            // получаем колонки
            this.selected = columns;
            this.getDefaultHeaders();
            // получаем events
            this.selectedCategory = this.getQueryByKey('events');
            this.isShowCategory = true;
            this.setFiltersParamsObject({ events: this.selectedCategory });
            this.checkboxesCategory = this.getCheckboxesCategory();
            // получаем фильтры
            this.getFilters();
            this.filters.parseRoute();
            this.getTableData(0);
        }
    }

    getFilters(): void {
        // очищаем filter.select
        this.clearFiltersSelect();
        this.filters.filters = !!this.tab ? this.filters.filters.filter( i => i.id !== 'published' && i.id !== 'created_by') : [...new NotificationFilters().filters];
    }

    changeCheckboxesCategory(): void {
        this.checkboxesCategory = this.getCheckboxesCategory();
        this.getDefaultCheckboxesCategory();
    }

    getDefaultCheckboxesCategory(): void {
        this.selectedCategory = this.checkboxesCategory.map( i => i.items!.map(j => j.value)).reduce((acc, it) => [...acc, ...it], []);
    }

    // очищаем все фильтры
    clearAllFilters(): void{
        // ставим дефолтные колонки
        this.getDefaultSelected();
        // ставим дефолтные категории
        this.selectedCategory = [];
        this.changeCheckboxesCategory();
        this.setFiltersParamsObject({ is_auto: String(!!this.tab), events: this.selectedCategory });
        this.updateQueryString({ ...this.$route.query, ...{ is_auto: String(!!this.tab) }, ...{ events: this.selectedCategory } });
    }

    // сброс категорий
    clearCategory(): void {
        this.changeCheckboxesCategory();
    }

    // сбросить фильтры filter.select
    clearFiltersSelect(): void {
        this.filters.filters.map((filter) => filter.clear());
        if (this.getFiltersParamsObject.hasOwnProperty('events')) {
            this.setFiltersParamsObject({ events: this.getFiltersParamsObject.events, is_auto: String(!!this.tab) });
        } else {
            this.setFiltersParamsObject({ is_auto: String(!!this.tab) });
        }
    }

    setTabToUrl(): void {
        const params = { ...this.getFiltersParamsObject, ...{ is_auto: String(!!this.tab) } };
        this.updateQueryString(params);
    }

    setCategoriesToFilters(): void {
        // добавляем в фильтры categories
        const params = { ...this.getFiltersParamsObject, ...{ events: [...this.selectedCategory] } };
        this.setFiltersParamsObject(params);
        // прокидываем выбранные категории в урл
        if (Object.keys(params)?.length > 0) {
            this.updateQueryString(params);
        }
    }

    getQueryByKey(key: string): string[] {
        return Array.isArray(this.$route.query[key]) ? [...this.$route.query[key]] as string[] : [this.$route.query[key]] as string[];
    }

    getNumberTab(): number {
        return this.$route.query?.is_auto! ==='true' ? 1 : 0;
    }


    created(): void {
        this.tab = this.getNumberTab();
        if (Object.keys(this.$route.query)?.length <= 0) {
            this.setTabToUrl();
        }
        // находим выбранные колонки (selected)
        if (this.$route.query.hasOwnProperty('columns')) {
            this.selected = this.getQueryByKey('columns');
        } else {
            this.getDefaultSelected();
        }
        // получаем выбранные категории из урла
        if (this.$route.query.hasOwnProperty('events')) {
            this.selectedCategory = this.getQueryByKey('events');
            this.isShowCategory = true;
        }
        // меняем список фильтров
        this.filters.filters = !!this.tab ? this.filters.filters.filter( i => i.id !== 'published' && i.id !== 'created_by') : [...new NotificationFilters().filters];
        // возможные дефолтные заголовки
        this.getDefaultHeaders();
    }

    async mounted(): Promise<void> {
        await this.getEvents();
        // если категории не переданы в урле, берем дефолтные
        if (this.selectedCategory.length <= 0) {
            this.getDefaultCheckboxesCategory();
            this.isShowCategory = true;
        }
        await this.getNotifications();
    }

    async getNotifications(offset?: number): Promise<void> {
        this.settings.loading = true;
        try {
            const params = { ...this.getFiltersParamsObject, ...{ is_auto: String(!!this.tab) } };
            this.setFiltersParamsObject(params);
            this.updateQueryString(params);
            this.setCategoriesToFilters();
            await this.getTableData(offset);
        } catch (err) {
            showServerError(err, 'Опросы не получены');
        } finally {
            this.settings.loading = false;
        }
    }

    filterEvent(e: IEvents): boolean {
        return (this.tab === 0 && e.type === 'manual')
            || (this.tab === 1 && e.type === 'auto') || e.type === 'mixed';
    }

    getCheckboxesCategory(): IColumnFilter[] {
        return this.events.map((e) => {
            const items = e.events.filter((e) => this.filterEvent(e)).map(i => {
                return { title: i.name, value: i.value };
            });
            const values = items.map(i => i.value);
            return {
                name: e.category.name,
                value: this.selectedCategory.length > 0 ? intersection(this.selectedCategory, values).length >= values.length : true,
                items: items,
            };
        }) || [];
    }

    async getEvents(): Promise<void> {
        try {
            this.events = await getNotificationEvents();
            this.checkboxesCategory = this.getCheckboxesCategory();
        } catch(err) {
            showServerError(err, 'Категории не получены');
        }
    }

    getDefaultSelected(): void {
        this.selected = this.tab ? [...defaultAutoColumns] : [...defaultColumns];
    }

    getDefaultHeaders(): void {
        this.headers = this.tab ? autoNotificationsHeaders  : notificationsHeaders ;
    }

    addRowClass(item: INotificationList): string {
        if (new Date(item.pinned_to!) >= new Date() && new Date(item.pinned_from!) <= new Date()) {
            return 'row--is-pinned';
        } else {
            return '';
        }
    }

    cutTags(message: string): string {
        const cuttedTags = message.replace(/(<([^>]+)>|&nbsp;)/ig, ' ').trim();
        return cuttedTags.replace(/(.{200})/g, '$1\n');
    }
}
