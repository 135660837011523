
import { Component, Vue, Prop, PropSync } from 'vue-property-decorator';
import { OfferSingle } from "@/services/offers/OfferSingle";
import { getFormatDate } from "@/utils/formatDates";
import SvgError from "@/assets/icons/false-icon.svg";
import { eventBus } from "@/eventbus";
import Status from "@/components/base/Status.vue";
import ImageInput from "@/components/base/form/ImageInput.vue";
import TooltipCountryList from "@/components/base/TooltipCountryList.vue";
import TagSelect from "@/components/base/TagSelect.vue";
import SvgPrivateOffer from "@/assets/icons/private-offer.svg";
import BaseSelect from "@/components/base/design/BaseSelect.vue";
import BaseAutocomplete from "@/components/base/design/BaseAutocomplete.vue";
import { IBrand, IEditableOfferFields } from "@/api/types/offers";
import { createOfferTag, getBrandsSearch, getOfferTags } from "@/api/offers";
import { translateOffersStatus } from "@/utils/translate";
import { getAdvertisersSearch } from "@/api/revenue";
import { IAdvertiser } from "@/api/types/revenue";
import OfferContestList from "@/components/offers/offer/OfferContestList.vue";
import BaseCard from "@/components/base/BaseCard.vue";
import QuestionMarkTooltip from "@/components_v3/base/QuestionMarkTooltip.vue";
import { namespace } from "vuex-class";
import { getCurrentDate } from '@/utils/formatDates';
import BaseTooltip from '@/components_v3/base/formComponents/BaseTooltip.vue';
import DateTimePicker from "@/components_v3/base/formComponents/pickers/DateTimePicker.vue";
import { IBaseAutocomplete } from "@/types";

interface IHeaderOffer {
    name: string;
    value: any;
    type: string;
    tooltip?: string
}

const catalogue = namespace('catalogueModule');

@Component({
    components: {
        DateTimePicker,
        QuestionMarkTooltip,
        BaseCard,
        OfferContestList,
        BaseAutocomplete,
        BaseSelect,
        SvgPrivateOffer,
        TagSelect,
        TooltipCountryList,
        ImageInput,
        Status,
        SvgError,
        BaseTooltip,
    },
})
export default class OfferCardMain extends Vue {
    @Prop({ required: true }) offer!: OfferSingle;
    @PropSync('isLoading') isLoadingSync!: boolean;

    @catalogue.Getter('GET_CURRENCIES') currencies;

    getFormatDate = getFormatDate;
    getOfferTags = getOfferTags;
    createOfferTag = createOfferTag;
    translateOffersStatus = translateOffersStatus;
    statusItems = [{ id: 'active' }, { id: 'paused' }, { id: 'deleted' }, { id: 'pending' }];
    isEdit = false;
    tagsValue: number[] = [];
    statusVal: string = '';
    offerData: IEditableOfferFields = {
        status: '',
        action_date: '',
        currency: '',
    } as IEditableOfferFields;
    dateForEqual: string = '';

    get getOptions(): IHeaderOffer[] {
        const options = [
            { name: 'Название', value: this.offer.name, type: 'title' },
            { name: 'Бренд', value: this.offer.brand, type: 'brand' },
            { name: 'Статус', value: this.offer.status, type: 'status' },
            { name: 'Валюта', value: this.offer.currency, type: 'currency', tooltip: 'При изменении валюты оффера, индивидуальные цены будут переведены в статус “На паузе"' },
            { name: 'URL по умолчанию', value: this.offer.preview_url, type: 'url' },
            { name: 'Менеджер', value: this.offer.manager, type: 'manager' },
            { name: 'Рекламодатель', value: this.offer.advertiser, type: 'advertiser' },
            { name: 'Чеккер', value: this.offer.advertiser!.is_checker_used, type: 'checker', tooltip: 'Изменить это свойство можно на детальной странице рекламодателя.' },
            { name: 'Дата действия', value: this.offer.expiration_date, type: 'date' },
            { name: 'Создан', value: this.offer.created_at, type: 'date' },
            { name: 'Теги', value: this.offer.tags, type: 'tags' },
        ];

        return this.isEdit ? options : options.slice(1);
    }

    get getStatusItems(): { id: string, text: string }[] {
        if (this.offer.status === 'capped') {
            this.statusItems = [...this.statusItems, ...[{ id: 'capped' }]];
        }

        return this.statusItems.map(i => ({
            id: i.id,
            text: translateOffersStatus(i.id).text,
        }));
    }

    get getAdvertiserSelectData(): any {
        return {
            apiMethod: getAdvertisersSearch,
            template: (item: IAdvertiser) => item.id + ' ' + item.name,
            itemValue: 'id',
        };
    }

    get getBrandData(): IBaseAutocomplete {
        return {
            apiMethod: getBrandsSearch,
            template: (item: IBrand) => item.name,
            itemText: 'name',
            itemValue: 'id',
            key: 'search',
        };
    }

    get getOfferTagIds(): number[] {
        return this.offer.tags.map(t => +t.id);
    }

    get isDisabledEditMode(): boolean {
        const curDate = getCurrentDate('yyyy-MM-dd');
        const editableValue: any = this.dateForEqual ?
            this.dateForEqual :
            this.offer.expiration_date!.split(' ')[0];

        return this.isEdit ? new Date(editableValue) < new Date(curDate) : false;
    }

    handleDateValueUpdate(date: string): void {
        this.offerData.expiration_date = date;
        this.dateForEqual = date;
    }

    editInfo(edit: boolean): void {
        if (edit) {
            this.setDefaults();
        } else {
            this.updateAllOfferData();
        }
        this.isEdit = edit;
    }

    cancelEdit(): void {
        this.isEdit = false;
        this.offerData.expiration_date = '';
        this.dateForEqual = '';
    }

    setDefaults(): void {
        this.statusVal = this.offer.status;
        this.offerData.action_date = getCurrentDate('yyyy-MM-dd HH:mm');
        this.offerData.name = this.offer.name;
        this.offerData.brand = this.offer.brand;
        this.offerData.status = this.offer.status;
        this.offerData.preview_url = this.offer.preview_url;
        this.offerData.advertiser = this.offer.advertiser?.legacy_id!;
        this.offerData.expiration_date = this.offer.expiration_date!;
        this.offerData.currency = this.offer.currency;
        this.tagsValue = this.getOfferTagIds;
    }

    selectAdvertiser(id: number): void {
        this.offerData.advertiser = id;
    }

    selectBrand(item: IBrand): void {
        this.offerData.brand = item.name;
    }

    async updateAllOfferData(): Promise<void> {
        this.isLoadingSync = true;
        if (this.statusVal !== 'capped') {
            this.offerData.status = this.statusVal;
        } else {
            delete this.offerData.status;
        }
        const isOfferDataSaved = await this.offer.editOffer(this.offerData);
        const isTagsSaved = await this.offer.saveTags(this.tagsValue);
        if (isOfferDataSaved || isTagsSaved) {
            eventBus.$emit("update-offer");
        }
        this.isLoadingSync = false;
    }
}
