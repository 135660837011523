
import { Component, Vue, Prop } from 'vue-property-decorator';
import { getFormatDate } from "@/utils/formatDates";
import { Advertiser, IProject, PROJECTS } from "@/services/advertiser/Advertiser";
import { translateAdvertiserStatus, translateBoolean } from "@/utils/translate";
import BaseSelect from "@/components/base/design/BaseSelect.vue";
import { ITag } from "@/api/types/revenue";
import { namespace } from "vuex-class";
import { ICurrency } from "@/api/types/catalogue";
import Loader from "@/components/base/Loader.vue";
import BaseCard from "@/components/base/BaseCard.vue";
import BaseAutocomplete from "@/components/base/design/BaseAutocomplete.vue";
import { getActiveUserSearch } from "@/api/user";
import { IBaseAutocomplete } from "@/types";
import BaseTooltip from '@/components_v3/base/formComponents/BaseTooltip.vue';

const catalogue = namespace('catalogueModule');

enum Keys {
    Name = 'name',
    Status = 'status',
    Manager = 'manager_id',
    Tag = 'tag',
    Projects = 'projects',
    Currency = 'currency',
    Checker = 'is_checker_used',
}
type SelectItem = ITag | IProject | ICurrency;

@Component({
    methods: { translateBoolean, getFormatDate, translateAdvertiserStatus },
    components: {
        BaseAutocomplete,
        BaseCard,
        Loader,
        BaseSelect,
        BaseTooltip,
    },
})

export default class AdvertiserAbout extends Vue {
    @catalogue.Getter('GET_CURRENCIES') currencies!: ICurrency[];
    @Prop({ required: true }) advertiser!: Advertiser;

    isLoading: boolean = false;
    isEdit: boolean = false;
    advertiserData = {};
    selectKey = 0;
    Keys = Keys;
    selects = [Keys.Status, Keys.Tag, Keys.Currency, Keys.Projects];
    editable = [...this.selects, Keys.Checker, Keys.Name];


    get getOptions(): { name: string, value: any, key: Keys, isHidden: boolean }[] {
        const options = [
            { name: 'Название', value: this.advertiser.name, key: Keys.Name, isHidden: true },
            { name: 'Статус', value: this.advertiser.status, key: Keys.Status, isHidden: true },
            { name: 'Менеджер', value: this.getManagerName, key: Keys.Manager, isHidden: false },
            { name: 'Тег', value: this.advertiser.tag, key: Keys.Tag, isHidden: false },
            { name: 'Проект', value: this.advertiser.projects, key: Keys.Projects, isHidden: false },
            { name: 'Валюта', value: this.advertiser.currency, key: Keys.Currency, isHidden: false },
            { name: 'Чеккер', value: this.advertiser.is_checker_used, key: Keys.Checker, isHidden: false },
        ];

        if (this.isEdit) {
            return options;
        } else {
            return options.filter((item) => !item.isHidden);
        }
    }

    getSelectOptions(key: string): { itemValue: string, itemText: string, template?: () => string, items: SelectItem[], isMultiple: boolean } {
        const options = {
            status: {
                itemValue: 'value',
                itemText: 'value',
                items: this.getStatuses,
                template: (status: any) => translateAdvertiserStatus(status.value).text,
                isMultiple: false,
            },
            tag: { itemValue: 'id', itemText: 'name', items: this.advertiser.allTags, isMultiple: false },
            projects: { itemValue: 'abbr', itemText: 'text', template: (item: IProject) => `${item.abbr} - ${item.text}`, items: PROJECTS, isMultiple: true },
            currency: { itemValue: 'code', itemText: 'code', items: this.currencies, isMultiple: true },
        };

        return options[key];
    }

    get getStatuses(): { id: number, value: string }[] {
        return [
            { id: 1, value: 'active' },
            { id: 2, value: 'deleted' },
            { id: 3, value: 'blocked' },
            { id: 4, value: 'rejected' },
            { id: 5, value: 'pending' },
        ];
    }

    get getManagerAutocompleteData(): IBaseAutocomplete {
        return {
            placeholder: 'Менеджер рекламодателя',
            errorText: 'Менеджер не найден',
            apiMethod: getActiveUserSearch,
            multiple: false,
            itemValue: 'id',
            clearable: true,
            template: (item: any) => `${item.id} ${item.first_name} ${item.last_name}`,
            maxShowItems: 20,
        };
    }

    get getManagerName(): string {
        if (!this.advertiser || !this.advertiser.managers) return '';
        const manager = this.advertiser.account_manager!;
        return `${manager.id} ${manager.full_name}` || `${manager.id} ${manager.full_name} ${manager.last_name}` || '';
    }

    get getManagerId(): number | string {
        if (!this.advertiser || !this.advertiser.managers) return '';
        const manager = this.advertiser.account_manager!;
        return manager.id || '';
    }

    setManager(id: number): void {
        this.advertiserData[Keys.Manager] = id;
    }

    editInfo(edit: boolean): void {
        if (edit) {
            this.setValues();
        } else {
            this.advertiser.editMainInfo(this.advertiserData);
        }
        this.isEdit = edit;
    }

    cancelEdit(): void {
        this.isEdit = false;
    }

    setValues(): void {
        this.advertiserData[Keys.Manager] = this.getManagerId;
        this.editable.forEach(id => {
            const value = JSON.parse(JSON.stringify(this.advertiser[id]));
            this.advertiserData[id] = id === Keys.Tag ? value.id : value;
        });
    }

    getValue(key: Keys): string[] {
        const values = this.advertiser[key];
        const { items, itemValue, itemText } = this.getSelectOptions(key);
        if (!values || values.length === 0) return [];
        if (key === Keys.Tag) return [values[itemText]];
        if (key === Keys.Status) return [values];
        return values.map((i: any) => items.find((item: SelectItem) => item[itemValue] === i)?.[itemText]!);
    }
}
