import Vue from 'vue';
import Component from 'vue-class-component';
import { Getter, Mutation } from 'vuex-class';
import AppTable from '@/components/base/table/AppTable.vue';
import AppTableDesign from '@/components/base/table/AppTableDesign.vue';
import Filters from '@/components/base/filters/Filters.vue';
import { eventBus } from '@/eventbus';
import { PAGINATION_PER_PAGE } from '@/configs/global';
import { TableHeader } from "@/types";
import { IFilter } from "@/types";

@Component({
    // @ts-ignore
    components: {
        AppTable,
        AppTableDesign,
        Filters,
    },
})
export default class TableMixin extends Vue {
    @Getter('GET_FILTERS_PARAMS_OBJECT') getFiltersParamsObject;
    @Mutation('SET_DEFAULT_FILTERS_PARAMS_OBJECT') setDefaultParamsObject;
    @Mutation('SET_FILTERS_PARAMS_OBJECT') setFiltersParamsObject;
    @Getter('GET_DEFAULT_FILTERS_PARAMS_OBJECT') getDefaultFiltersParamsObject;

    dataTable: any[] = []; // данные для таблицы получаемые из API
    apiMethod;
    additionalArguments: any = [];

    dataTableSummary: any[] = [];
    apiMethodSummary;

    // для пагинации
    rowsPerPage = PAGINATION_PER_PAGE || 100;
    totalElementDataTable: number | undefined = 0;
    renderComponent = true;
    loading = false;
    baseOffset = 0;
    itemsClass;
    paramsModifier;
    isNewDesignPage = false;

    search: string = '';

    // используется для передачи по пропсам в фильтр выбранное количество строк в селекте
    get limit(): number {
        return this.rowsPerPage;
    }

    async clearFilter(): Promise<void> {
        await this.getTableData(0, this.getDefaultFiltersParamsObject);
        await eventBus.$emit('go-to-first-page');
    }

    async getTableData(offset: number = this.baseOffset, params?: any,  limit: number = this.rowsPerPage): Promise<void> {
        const settings = 'settings';
        // используем эту функцию для принудительного обновления компонента, чтобы зайти в хуки
        if (offset === 0) {
            await eventBus.$emit('go-to-first-page');
        }
        try {
            this[settings].loading = true;
            this.rowsPerPage = limit;
            let paramsObj = {};
            // для страниц в новом дизайне
            if (this.isNewDesignPage) {
                paramsObj = params ? params : this.getFiltersParamsObject;
            } else {
                // для страниц в старом дизайне
                paramsObj = { ...this.getDefaultFiltersParamsObject, ...this.getFiltersParamsObject };
            }
            if (typeof this.paramsModifier === 'function') {
                paramsObj = this.paramsModifier(paramsObj);
            }
            const resultParams = await this.transformParamsObjBeforeSubmit({  limit, ...paramsObj, offset, search: this.search });
            //удалить пустые параметры
            for (const key of Object.keys(resultParams)) {
                if (resultParams[key] === '') {
                    delete resultParams[key];
                }
            }

            const paramsArgs = Array.isArray(resultParams) ? resultParams : [resultParams];
            const { data, meta: { total } } = await this.apiMethod(...this.additionalArguments, ...paramsArgs);
            this.dataTable = data.length > 0
                ? await this.transformDataTableAfterSubmit(!!this.itemsClass ? data.map((i: any) => new this.itemsClass(i)) : data)
                : [];
            this.totalElementDataTable = total || 0;

            if (this.apiMethodSummary) {
                const { data: dataSummary } = await this.apiMethodSummary(...paramsArgs);
                this.dataTableSummary = dataSummary;
            }
        } finally {
            this[settings].loading = false;
        }
    }

    // метод для переопределения отправляемых данных на странице
    transformParamsObjBeforeSubmit(paramsObj: any): any | Promise<any> {
        return paramsObj;
    }

    // метод для переопределения приходящих данных на странице
    transformDataTableAfterSubmit(data: any): any | Promise<any> {
        return data;
    }

    created(): void {
        this.setDefaultParamsObject({});
        this.setFiltersParamsObject({});
        // значение offset по умолчанию: this.$route.query.offset-если был переход по прямой ссылке или 0
        this.baseOffset = +this.$route.query.offset || 0;
        this.search = this.$route.query.search as string || '';
    }

    clearSelectFilters(filters: IFilter[]): void {
        if (!filters) return;
        filters.map((filter) => {
            filter.clear();
            if (this.getDefaultFiltersParamsObject[filter.id] !== undefined && (!filter.select || filter.select?.length <= 0)) {
                filter.select = this.getDefaultFiltersParamsObject[filter.id];
            }
        });
    }

    mounted(): void {
        const settings = 'settings';
        this[settings] = {
            loading: this[settings].loading,
            searchPlaceholder: this[settings].searchPlaceholder || 'Поиск...',
            title: this[settings].title,
            loadingExport: this[settings].loadingExport || false,
        };
    }

    updateQueryString(data: any): void {
        const query = { ...data };
        this.$router.replace({ name: this.$router.currentRoute.name || '', query }).catch((error) => {
            if (error.name !== 'NavigationDuplicated') {
                throw error;
            }
        });
    }

    updateFilterHeaders(headers: TableHeader[], selected: any = this.$route.query.columns): TableHeader[] {
        const filteredHeaders: TableHeader[] = [];
        headers.forEach((header: TableHeader) => {
            if (Array.isArray(selected) && selected.includes(header.value)) {
                return filteredHeaders.push(header);
            } else if (header.value === selected) {
                return filteredHeaders.push(header);
            }
        });
        return filteredHeaders;
    }

}
