
import { Vue, Component, Prop } from "vue-property-decorator";
import AppTableDesign from "@/components/base/table/AppTableDesign.vue";
import { TableHeader } from "@/types";
import { Offer } from "@/services/offers/Offer";
import { translateChecker, translateOffersStatus } from "@/utils/translate";
import BaseTooltip from "@/components_v3/base/formComponents/BaseTooltip.vue";

@Component({
    components: {
        AppTableDesign,
        BaseTooltip,
    },
})

export default class CategoriesTable extends Vue {
    @Prop({ required: true }) items!: Offer[];
    @Prop({ default: 100 }) limit!: number;
    @Prop({ default: 0 }) total!: number;
    @Prop({ default: true }) loading!: boolean;

    headers:TableHeader[] = [
        { text: 'ID', sortable: false, value: 'id', align: 'center' },
        { text: 'Название', sortable: false, value: 'name' },
        { text: 'Аккаунт-менеджер', sortable: false, value: 'account_manager.full_name' },
        { text: 'Чеккер', sortable: false, value: 'is_checker_used' },
        { text: 'Статус', sortable: false, align: 'center', value: 'status' },
    ];

    routes = [
        {
            routeParam: 'id',
            slot: 'item.id',
            routerName: 'advertiser',
            text: 'id',
        },
        {
            routeParam: 'id',
            slot: 'item.name',
            routerName: 'advertiser',
            text: 'name',
        },
    ];

    uniqueData = [
        {
            slot: 'item.account_manager.full_name',
        },
    ];

    statuses = [
        {
            slot: 'item.status',
            key: 'status',
            translate: (status: string): any => translateOffersStatus(status),
        },
        {
            slot: 'item.is_checker_used',
            key: 'is_checker_used',
            translate: (status: boolean): any => translateChecker(!!status),
        },
    ];
    submitData(offset: number, limit: number): void {
        this.$emit('update-table', offset, limit);
    }
}

