
import { Component, Vue, Prop } from 'vue-property-decorator';

export interface ITdRoute {
    slot: string,
    routerName: string,
    routeParam: string,
    text: string,
    tooltip?: Function,
    tooltipClass?: string,
    template?: Function,
    sup?: string,
    getParams?: Function,
    getQuery?: Function,
    extraRouteParamName?: string,
    icon?: any,
    asideIcon?: any,
    showIcon?: Function,
    routeAction?: Function,
}

@Component({
    components: {},
})
export default class TdRoute extends Vue {
    @Prop({ required: true }) item!: Object | any;
    @Prop({ required: true }) route!: ITdRoute;

    get isShowTooltip(): boolean {
        return !!(this.route.tooltip && String(this.route.tooltip(this.item)) && String(this.route.tooltip(this.item)).length > 0);
    }

    get textBtn(): string | number {
        if (this.route.sup) {
            return this.item[this.route.sup][this.route.text];
        } else {
            return this.route.template ? this.route.template(this.item) : this.item[this.route.text];
        }
    }

    routeParams(): { [key: string]: string } | null {
        let params: { [key: string]: string };

        if (this.route.sup) {
            params = {
                [this.route.routeParam]: String(this.route.extraRouteParamName
                    ? this.item[this.route.sup][this.route.extraRouteParamName]
                    : this.item[this.route.sup][this.route.routeParam]),
            };
        } else {
            if (this.route.getParams) {
                params = this.route.getParams(this.item, this.route);
            } else {
                params = {
                    [this.route.routeParam]: String(this.route.extraRouteParamName
                        ? this.item[this.route.extraRouteParamName]
                        : this.item[this.route.routeParam]),
                };
            }
        }
        if (!params[this.route.routeParam]) return null;
        return params;
    }

    queryParams(): any {
        let params = {};

        if (!this.route.sup) {
            params = this.route.getQuery ? this.route.getQuery(this.item) : null;
        }

        return params;
    }

    get getRouteData(): any {
        const name = this.route.routerName;
        const params = this.routeParams();
        const query = this.queryParams();
        return { name, params, query };
    }

    get getHref(): string {
        if (!this.getRouteData.params) return '';
        return this.$router.resolve(this.getRouteData).href as string;
    }
}
